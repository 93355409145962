import axios from "axios"

class ApiError extends Error {
    constructor(message, attributes, status = 200) {
        super(message)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError)
        }
        this.name = "ApiError"
        this.attributes = attributes || {}
        this.status = status
    }
}

const request = (method, url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        axios({ method, url, [method === "get" ? "params" : "data"]: data, headers })
            .then(response => {
                const { status, data } = response
                if (status >= 200 && status < 300) {
                    console.log("*** response", data)
                    resolve(data.meta ? data : data.data)
                } else {
                    console.log("*** failed with status", status)
                    reject(new ApiError(data.error.message, data.error.attributes, status))
                }
            })
            .catch(error => reject(error))
    })
}

const getRequest = (path, params, headers = {}) => request("get", path, params, headers)
const postRequest = (path, params, headers = {}) => request("post", path, params, headers)
const patchRequest = (path, params, headers = {}) => request("patch", path, params, headers)
const deleteRequest = (path, params = null, headers = {}) => request("delete", path, params, headers)

export {
    ApiError,
    getRequest,
    postRequest,
    patchRequest,
    deleteRequest,
}
