import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFeaturedVideos = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }

    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/featured_videos", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFeaturedVideo = (token, { id }) => {
    return getRequest(`/admin/featured_videos/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFeaturedVideo = (token, featuredVideo) => {
    if (featuredVideo.id) {
        let { id, ...featuredVideoWithoutId } = featuredVideo
        return patchRequest(`/admin/featured_videos/${ id }`, {
            featured_video: featuredVideoWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/featured_videos", {
            featured_video: featuredVideo
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFeaturedVideo = (token, featuredVideo) => {
    return deleteRequest(`/admin/featured_videos/${ featuredVideo.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
