export default {
    data() {
        return {
            scTimer: 0,
            scY: 0,
        }
    },
    methods: {
        getPageHeight: function() {
            var body = document.body,
                html = document.documentElement;

            var height = Math.max( body.scrollHeight, body.offsetHeight, 
                                   html.clientHeight, html.scrollHeight, html.offsetHeight );
            return height/1.3;
        },

        handleScroll: function () {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 100);
        },

        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll);
    },
}
