import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFeaturedRanges = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/featured_ranges", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFeaturedRange = (token, { id }) => {
    return getRequest(`/admin/featured_ranges/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFeaturedRange = (token, featuredRange) => {
    if (featuredRange.id) {
        let { id, ...FeaturedRangeId } = featuredRange
        return patchRequest(`/admin/featured_ranges/${ id }`, {
            featured_range: FeaturedRangeId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/featured_ranges", {
            featured_range: featuredRange
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFeaturedRange = (token, featuredRange) => {
    return deleteRequest(`/admin/featured_ranges/${ featuredRange.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
