<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="dark"
        class="p-3"
        sticky
    >
        <b-navbar-brand to="/admin">
            design<strong>guide</strong> admin
        </b-navbar-brand>

        <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">FAQs</b-nav-item>
            <b-nav-item href="#">About</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
            <b-nav-form  @submit.stop.prevent="onSearch">
                <b-form-input
                    v-model="searchQuery"
                    size="sm"
                    class="mr-sm-2"
                    placeholder="Search"
                />

                <b-button
                    size="sm"
                    class="my-2 my-sm-0"
                    type="submit"
                >
                    Search
                </b-button>
            </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav class="ml-4">
            <b-button
                size="sm"
                class="my-2 my-sm-0"
                type="submit"
                variant="warning"
                @click.stop.prevent="onAdminSignOut"
            >
                Sign Out
            </b-button>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
// import { suggestFirms } from "api/firms"

export default {
    data () {
        return {
            searchMode: false,
            searchQuery: this.$route.query.query || "",
            selectType: "building_products",
            selectTypes: {
                building_products: "Building Products",
                design_firms: "Design Firms"
            },
        }
    },

    computed: {
        ...mapGetters("account", ["isAuthorized", "currentUser"]),

        selectTypeTitle () {
            return this.selectTypes[this.selectType]
        },
    },

    methods: {
        ...mapActions("account", ["signout", "adminSignout"]),

        onSignOut () {
            this.signout()
                .then(() => {
                    this.$router.push({ name: "home" })
                })
        },

        onAdminSignOut () {
            this.adminSignout(null)
            this.$router.go()
        },
        onSearch () {
            this.$router.replace({
                name: this.$route.name,
                query: Object.assign({}, this.$route.query, { query: this.searchQuery})
            }).catch(() => {})
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar-brand {
    color: #fff;
}

@media (min-width: 992px) {
    .me-lg-auto {
        margin-right: auto !important;
    }
}
</style>
