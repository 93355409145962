<template>
    <div class="content">
        <b-row>
            <b-col>
                <h1>Newsletter Signups <span v-if="tabCounts.active_count > 0">({{ tabCounts.active_count }})</span></h1>
<!--                 <b-tabs
                    v-model="currentTab"
                    content-class="mt-3"
                    >
                    <b-tab :title="ActiveTab" />
                    <b-tab :title="InactiveTab" />
                </b-tabs> -->
            </b-col>
            <!-- <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3 justify-content-end"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            v-if="currentTab == 0"
                            class="mr-2"
                            _to="{ name: 'admin-new-claim' }"
                            variant="danger"
                            :disabled="!isSomeChecked"
                            @click.prevent="onClickOperation('inactive')"
                        >
                            Inactive
                        </b-button>

                        <b-button
                            v-else
                            class="mr-2"
                            _to="{ name: 'admin-new-review' }"
                            variant="success"
                            :disabled="!isSomeChecked"
                            @click.prevent="onClickOperation('active')"
                        >
                            Active
                        </b-button>

                        <b-button
                            @click="onClickOperation('export')"
                        >
                            Export Newsletter Signup
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col> -->
        </b-row>

        <b-row>
            <b-col md="12">
                <b-navbar
                    type="light"
                    variant="light"
                    class="mb-1"
                >
                    <b-nav-form  @submit.stop.prevent="onSearch">
                        <b-form-datepicker
                            :id="`start_date_picker`"
                            :state="null"
                            :value="startDate ? moment(startDate).format('YYYY-MM-DD') : ''"
                            :reset-button="true"
                            class="mr-4"
                            @input="startDate = $event ? moment($event + 'T' + moment(startDate || undefined).format('HH:mm:ss')).toISOString() : null"
                        />
                        <b-form-datepicker
                            :id="`end_date_picker`"
                            :state="null"
                            :value="endDate ? moment(endDate).format('YYYY-MM-DD') : ''"
                            :reset-button="true"
                            class="mr-4"
                            @input="endDate = $event ? moment($event + 'T' + moment(endDate || undefined).format('HH:mm:ss')).toISOString() : null"
                        />

                        <b-button
                            size="sm"
                            class="my-2 my-sm-0"
                            type="submit"
                        >
                            Search
                        </b-button>
                        <b-button
                            size="sm"
                            class="my-2 my-sm-0 ml-2"
                            variant="danger"
                            @click.prevent="onClickOperation('delete')"
                            type="button"
                            :disabled="!isSomeChecked"
                        >
                            Delete
                        </b-button>
                    </b-nav-form>
                </b-navbar>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-newsletter-signup' }"
                        >
                            New Newsletter Signup
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-newsletter-signups' }"
                    :link-gen="linkGen"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            table-class=""
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            responsive
            @row-clicked="onClickRow"
        >
            <template #head(check)>
                <b-form-checkbox
                    :checked="isEveryChecked"
                    :indeterminate="indeterminateCheckAll"
                    @change="onClickedCheckAll"
                />
            </template>

            <template #cell(check)="row">
                <b-form-checkbox
                    v-model="row.item.isChecked"
                />
            </template>
            <template #cell(country)="row">
                {{ row.item.country | country }}
            </template>

            <template #cell(active)="row">
                {{ row.item.active ? "Yes" : "No" }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table>

        <div class="d-flex justify-content-end">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="lastPage"
                :limit="7"
                :to="{ name: 'admin-newsletter-signups' }"
                :link-gen="linkGen"
                :disabled="paginationDisable"
                @page-click="onPageClick"
            />
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone"
import { ApiError } from "api/base"
import { getNewsletterSubscriptions, exportNewsletterSubscription } from "api/admin/newsletter-subscriptions"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            currentTab: 0,
            moment,
            startDate: null,
            endDate: null,
            paginationDisable: false,
            items: [],
            totalItems: 0,
            itemsPerPage: 25,
            sortBy: "created_at",
            sortDesc: true,
            tabCounts: {
                active_count: 0,
                inactive_count: 0,
            },
            fields: [
                { key: "check", sortable: false },
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "name", label: "Name", sortable: true, class: "text-nowrap column-left" },
                { key: "email", label: "Email", sortable: true, class: "column-left", thStyle: { width: "25%" } },
                { key: "occupation", label: "Description", sortable: true, class: "column-left", thStyle: { width: "10%" } },
                { key: "state", label: "State", sortable: true, class: "text-nowrap column-left" },
                { key: "country", label: "Country", sortable: true, class: "text-nowrap column-left" },
                { key: "source", label: "Source", sortable: true, class: "text-nowrap column-left" },
                { key: "created_at", label: "Date", sortable: true, class: "text-nowrap column-center" },
                // { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
            tabStates: ['active','inactive']
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
        // ActiveTab() {
        //     return `Active ( ${this.tabCounts.active_count} )`
        // },
        // InactiveTab() {
        //     return `Inactive ( ${this.tabCounts.inactive_count} )`
        // },
        isSomeChecked () {
            return this.items.some(record => record.isChecked)
        },

        isEveryChecked () {
            return this.items.every(record => record.isChecked)
        },

        indeterminateCheckAll () {
            return !this.isEveryChecked && this.isSomeChecked
        }
    },

    watch: {
        "$route": "loadNewsletterSubscriptions",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },

        currentTab (newValue) {
            this.loadNewsletterSubscriptions();
        }
    },

    mounted () {
        this.loadNewsletterSubscriptions()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),
        onClickedCheckAll (isChecked) {
            this.items.forEach(record => record.isChecked = isChecked)
        },
        onApprove() {
            approveClaimRequests(this.adminToken, this.items.filter(i => i.isChecked).map(x => x.id))
                .then(() => {
                    this.loadClaimRequests()
                    this.$bvToast.toast("Succeeded!", {
                        title: "Saved.",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onDeny() {
            denyClaimRequests(this.adminToken, this.items.filter(i => i.isChecked).map(x => x.id))
                .then(() => {
                    this.loadClaimRequests()
                    this.$bvToast.toast("Succeeded!", {
                        title: "Saved.",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },
        onSearch () {
            this.loadNewsletterSubscriptions();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadNewsletterSubscriptions () {
            // this.page = this.$route.query.page || 1
            // this.sortBy = this.$route.query.sortBy || ""
            // this.sortDesc = !!this.$route.query.sortDesc
            this.paginationDisable = true;
            getNewsletterSubscriptions(this.adminToken, {
                page: this.currentPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                filters: {startDate:this.startDate, endDate:this.endDate},
                tab: this.tabStates[this.currentTab]
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    const records = result.data.data.map(record => Object.assign({}, record, { isChecked: false }))
                    this.items.splice(0, this.items.length, ...records)
                    this.tabCounts = result.data.counts
                    this.paginationDisable = false;
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onClickOperation (operation) {
            const message = `Are you sure you want to ${operation} this Newsletter Signup?`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        exportNewsletterSubscription(this.adminToken,{operation,requestIds: this.items.filter(i => i.isChecked).map(x => x.id) }).then(result => {
                            if(operation == 'export'){
                                window.open(result.file_url, "_blank")
                            }else{
                                window.location.reload();
                            }
                        }).catch(error => {
                            window.location.reload();
                            console.log('error',error);
                        })
                    }
            })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-show-newsletter-signup",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
</style>
