const employees = [
    { value: "self-employed", text: "Self-employed" },
    { value: "1-10", text: "1 ﹣ 10" },
    { value: "11-50", text: "11 ﹣ 50" },
    { value: "51-200", text: "51 ﹣ 200" },
    { value: "201-500", text: "201 ﹣ 500" },
    { value: "501-1000", text: "501 ﹣ 1000" },
    { value: "1001-5000", text: "1001 ﹣ 5000" },
    { value: "5001-10000", text: "5001 ﹣ 10000" },
    { value: "10001+", text: "10001+" },
]

export default employees
