<template>
    <div class="content">
        <div class="d-inline-flex">
            <h1 v-if="!$route.params.id">New Design Firm</h1>
            <h1 v-else>Edit Design Firm “{{ attributes.company_name }}” </h1>
        </div>
        <div class="d-inline-flex float-right sticky-top" style="top:90px;">
            <b-button
                size="sm"
                variant="primary"
                class="px-5 float-right"
                @click="onSave"
                >
                Save
            </b-button>
        </div>

        <b-row class="pt-2 pb-4" v-if="$route.params.id">
            <b-button-group class="mx-1">
                <b-button
                variant="danger"
                @click="onClickDelete"
                >
                Delete
                </b-button>
                <b-button
                v-if="originalAttributes.profile_state != 'published'"
                variant="success"
                @click="onClickApprove"
                >
                Approve
                </b-button>
                <b-button
                v-if="originalAttributes.profile_state != 'published'"
                variant="danger"
                @click="onClickDeny"
                >
                Deny
                </b-button>
                <b-button
                variant="warning"
                @click="onClickSuspend"
                >
                Suspend
                </b-button>
                <b-button
                    target="_blank"
                    :to="{ name: 'firm-profile', params: { id: $route.params.id } }"
                >
                    Preview Profile
                </b-button>
                <b-button
                variant="warning"
                @click="onClickEmailAdmin"
                >
                Email Admin
                </b-button>
            </b-button-group>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_profile_state_fieldset"
                    label="Status"
                    label-for="firm_profile_state"
                    :invalid-feedback="profileStateError"
                    :state="profileStateState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="firm_profile_state"
                        v-model="attributes.profile_state"
                        :options="profileStateOptions"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_tier_fieldset"
                    label="Tier"
                    label-for="firm_tier"
                    :invalid-feedback="tierError"
                    :state="tierState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="firm_tier"
                        v-model="attributes.tier"
                        :options="tierOptions"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_company_name_fieldset"
                    label="Company Name"
                    label-for="firm_company_name"
                    :invalid-feedback="companyNameError"
                    :state="companyNameState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_company_name"
                        v-model="attributes.company_name"
                        :state="companyNameState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                :id="`firm_approved_at_fieldset`"
                label="Approved At"
                :label-for="`firm_approved_at`"
                label-cols-md="3"
                label-align-md="left"
                >
                    <b-form-datepicker
                    :id="`firm_approved_at`"
                    :state="null"
                    :value="attributes.approved_at"
                    :reset-button="true"
                    @input="attributes.approved_at = $event || null"
                    />
                </b-form-group>
            </b-col>
        </b-row> -->

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_email_fieldset"
                    label="Email"
                    label-for="firm_email"
                    :invalid-feedback="emailError"
                    :state="emailState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_email"
                        v-model="attributes.email"
                        :state="emailState"
                        type="email"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_phone_fieldset"
                    label="Phone Number"
                    label-for="firm_phone"
                    :invalid-feedback="phoneError"
                    :state="phoneState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_phone"
                        v-model="attributes.phone"
                        :state="phoneState"
                        type="tel"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_site_fieldset"
                    label="Site"
                    label-for="firm_site"
                    :invalid-feedback="siteError"
                    :state="siteState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_site"
                        v-model="attributes.site"
                        :state="siteState"
                        type="url"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_country_fieldset"
                    label="Country"
                    label-for="firm_country"
                    :invalid-feedback="countryError"
                    :state="countryState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="firm_country"
                        v-model="attributes.country"
                        :state="countryState"
                        :options="countries"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                Enter Country
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_state_fieldset"
                    label="State / Province"
                    label-for="firm_state"
                    :invalid-feedback="stateError"
                    :state="stateState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="firm_state"
                        v-model="attributes.state"
                        :options="states"
                        :state="stateState"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                Enter State / Province
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_zip_fieldset"
                    label="Zip / Postal Code"
                    label-for="firm_zip"
                    :invalid-feedback="zipError"
                    :state="zipState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_zip"
                        v-model="attributes.zip"
                        :state="zipState"
                        type="text"
                        class="text-right"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_city_fieldset"
                    label="City"
                    label-for="firm_city"
                    :invalid-feedback="cityError"
                    :state="cityState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_city"
                        v-model="attributes.city"
                        :state="cityState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_address_fieldset"
                    label="Address"
                    label-for="firm_address"
                    :invalid-feedback="addressError"
                    :state="addressState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_address"
                        v-model="attributes.address"
                        :state="addressState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_leed_member_fieldset"
                    label="Leed Certified"
                    label-for="firm_leed"
                    :invalid-feedback="leedMemberError"
                    :state="leedMemberState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-checkbox
                        id="firm_leed"
                        v-model="attributes.leed_member"
                        name="firm_leed_member_fieldset"
                    >
                    </b-form-checkbox>
<!--                     <b-form-input
                        id="firm_leed"
                        v-model="attributes.leed_member_name"
                        :state="leedMemberState"
                        type="text"
                        trim
                    /> -->
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_leed_fieldset"
                    label="Leed Certified Staff Name"
                    label-for="firm_leed"
                    :invalid-feedback="leedMemberNameError"
                    :state="leedMemberNameState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_leed"
                        v-model="attributes.leed_member_name"
                        :state="leedMemberNameState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_job_opportunities_fieldset"
                    label="Job Opportunities"
                    label-for="job_opportunities"
                    :invalid-feedback="jobOpportunitiesError"
                    :state="jobOpportunitiesState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-checkbox
                        id="job_opportunities"
                        v-model="attributes.job_opportunities"
                        name="firm_leed_member_fieldset"
                    >
                    </b-form-checkbox>
<!--                     <b-form-input
                        id="firm_leed"
                        v-model="attributes.leed_member_name"
                        :state="leedMemberState"
                        type="text"
                        trim
                    /> -->
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_job_opportunities_url_fieldset"
                    label="Job Opportunities URL"
                    label-for="firm_job_opportunities_url"
                    :invalid-feedback="jobOpportunitiesUrlError"
                    :state="jobOpportunitiesUrlState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="firm_job_opportunities_url"
                        v-model="attributes.job_opportunities_url"
                        :state="jobOpportunitiesUrlState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>


        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="current_service_id_fieldset"
                    label="Service"
                    label-for="current_service_id"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-input-group>
                        <b-form-select
                            id="current_service_id"
                            v-model="currentService"
                            :options="serviceOptions"
                        >
                            <template #first>
                                <b-form-select-option :value="null">
                                    Enter Service
                                </b-form-select-option>
                            </template>
                        </b-form-select>

                        <b-input-group-append>
                            <b-button
                                variant="outline-success"
                                @click.prevent="onAddService"
                            >
                                Add
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-list-group class="mt-4">
                        <b-list-group-item
                            v-for="firm_service in attributes.firm_services"
                            :key="`service-${firm_service.id}`"
                        >
                            <div
                                class="d-flex align-items-center"
                            >
                                <span
                                    class="flex-grow-1 align-self-center"
                                >
                                    {{ firm_service.service.parent && firm_service.service.parent.category_name
                                        ? `${firm_service.service.parent.name}: ${firm_service.service.name}`
                                        : firm_service.service.name }}
                                </span>

                                <!-- <div style="width: 64px; height: 36px;">
                                    <b-img
                                        :src="firm_service.image_path"
                                        blank-color="#aaa"
                                        style="width: 64px; height: 36px;"
                                    />
                                </div>

                                <b-form-file
                                    class="service-image-picker ml-2"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    accept="image/jpeg, image/png, image/gif"
                                    @input="onServiceImageUpload(firm_service, $event)"
                                />

                                <b-button
                                    variant="outline-warning"
                                    class="pull-right ml-2"
                                    @click.prevent="onResetService(firm_service)"
                                >
                                    Reset
                                </b-button> -->

                                <b-button
                                    variant="outline-danger"
                                    class="pull-right ml-2"
                                    @click.prevent="onRemoveService(firm_service)"
                                >
                                    Remove
                                </b-button>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="current_specialization_id_fieldset"
                    label="Specialization"
                    label-for="current_specialization_id"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-input-group>
                        <b-form-select
                            id="current_specialization_id"
                            v-model="currentSpecialization"
                            :options="specializationOptions"
                        >
                            <template #first>
                                <b-form-select-option :value="null">
                                    Enter Specialization
                                </b-form-select-option>
                            </template>
                        </b-form-select>

                        <b-input-group-append>
                            <b-button
                                variant="outline-success"
                                @click.prevent="onAddSpecialization"
                            >
                                Add
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-list-group class="mt-4">
                        <b-list-group-item
                            v-for="(firm_specialization,firm_specialization_index) in attributes.firm_specializations"
                            :key="`specialization-${firm_specialization_index}`"
                        >
                            <div
                                class="d-flex align-items-center"
                            >
                                <span
                                    class="flex-grow-1 align-self-center"
                                >
                                    {{ firm_specialization.specialization.parent
                                        ? `${firm_specialization.specialization.parent.category_name || firm_specialization.specialization.parent.name}: ${firm_specialization.specialization.name}`
                                        : firm_specialization.specialization.name }}
                                </span>

                                <!-- <div style="width: 64px; height: 36px;">
                                    <b-img
                                        :src="firm_specialization.image_path"
                                        blank-color="#aaa"
                                        style="width: 64px; height: 36px;"
                                    />
                                </div>

                                <b-form-file
                                    class="specialization-image-picker ml-2"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    accept="image/jpeg, image/png, image/gif"
                                    @input="onSpecializationImageUpload(firm_specialization, $event)"
                                />

                                <b-button
                                    variant="outline-warning"
                                    class="pull-right ml-2"
                                    @click.prevent="onResetSpecialization(firm_specialization)"
                                >
                                    Reset
                                </b-button> -->

                                <b-button
                                    variant="outline-danger"
                                    class="pull-right ml-2"
                                    @click.prevent="onRemoveSpecialization(firm_specialization)"
                                >
                                    Remove
                                </b-button>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    label="Images"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-file
                        v-model="imagePicker"
                        placeholder="Choose images or drop them here..."
                        drop-placeholder="Drop file here..."
                        accept="image/jpeg, image/png, image/gif"
                        multiple
                        @input="onImageUpload"
                    />

                    <!-- <b-button
                        variant="outline-warning"
                        class="pull-right ml-2"
                        @click.prevent="onResetSpecialization(firm_specialization)"
                    >
                        Reset
                    </b-button> -->
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-table-simple
                    small
                >
                    <b-thead>
                        <b-tr>
                            <b-th>Add Default</b-th>
<!--                             <b-th>Kind</b-th>
                            <b-th>Caption</b-th>
                            <b-th>Position</b-th> -->
                            <b-th>Service</b-th>
                            <b-th>Specialization</b-th>
                            <b-th>Image</b-th>
                            <b-th>Actions</b-th>
                        </b-tr>
                    </b-thead>

                    <b-tbody>
                        <b-tr
                            v-for="image in attributes.images"
                            :key="image.id"
                        >
                            <!-- <b-td>
                                <b-form-select
                                    v-model="image.kind"
                                    :options="imageKindOptions"
                                />
                            </b-td>

                            <b-td>
                                <b-form-input
                                    v-model="image.caption"
                                    type="text"
                                    trim
                                />
                            </b-td>

                            <b-td>
                                <b-form-input
                                    v-model="image.position"
                                    type="text"
                                    :disabled="image.kind !== 'cover'"
                                    trim
                                />
                            </b-td> -->
                            <b-form-checkbox
                                    v-model="image.kind"
                                    value="cover"
                                    unchecked-value="logo"
                                >
                            </b-form-checkbox>

                            <b-td>
                                <!-- :disabled="image.kind !== 'cover' || !!image.specialization_id" -->
                                <b-form-select
                                    v-model="image.service_id"
                                    :options="serviceOptions"
                                    :disabled="!!image.specialization_id"
                                    @input="onInputService"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null">
                                            Enter Service
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-td>

                            <b-td>
                                <!-- :disabled="image.kind !== 'cover' || !!image.service_id" -->
                                <b-form-select
                                    v-model="image.specialization_id"
                                    :options="specializationOptions"
                                    :disabled="!!image.service_id"
                                    @input="onInputSpecialization"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null">
                                            Enter Specialization
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-td>

                            <b-td>
                                <b-img
                                    :src="image.image_path"
                                    width="150"
                                    height="114"
                                    style="max-width: 150px; height: 114px;"
                                    thumbnail
                                />
                            </b-td>

                            <b-td style="vertical-align: middle;">
                                <b-button
                                    pill
                                    size="sm"
                                    variant="danger"
                                    title="Remove this image"
                                    @click.prevent="onRemoveImage(image)"
                                >
                                    <b-icon icon="x" />
                                </b-button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>

        
<!--         <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_keyword_list_fieldset"
                    label="Keywords"
                    label-for="firm_keyword_list"
                    :invalid-feedback="keywordListError"
                    :state="keywordListState"
                >
                    <b-form-tags
                        id="firm_keyword_list"
                        v-model="attributes.keyword_list"
                        :state="keywordListState"
                        remove-on-delete
                        separator=","
                        :limit="100"
                    />
                </b-form-group>
            </b-col>
        </b-row> -->
<!-- 
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_employees_fieldset"
                    label="Employees"
                    label-for="firm_employees"
                    :invalid-feedback="employeesError"
                    :state="employeesState"
                >
                    <b-form-input
                        id="firm_employees"
                        v-model="attributes.employees"
                        :state="employeesState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_offices_fieldset"
                    label="Offices"
                    label-for="firm_offices"
                    :invalid-feedback="officesError"
                    :state="officesState"
                >
                    <b-form-input
                        id="firm_offices"
                        v-model="attributes.offices"
                        :state="officesState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row> -->

<!--         <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="firm_established_fieldset"
                    label="Established"
                    label-for="firm_established"
                    :invalid-feedback="establishedError"
                    :state="establishedState"
                >
                    <b-form-input
                        id="firm_established"
                        v-model="attributes.established"
                        :state="establishedState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row> -->

        <b-row>
            <b-col>
                <b-button
                    size="sm"
                    variant="primary"
                    class="px-5"
                    @click="onSave"
                >
                    Save
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            id="send-admin-email"
            title="Email Admin"
            size="lg"
        >
            <h6>Message</h6>
            <b-form-textarea
                v-model="adminEmailMessage"
                required
                placeholder="Write your message"
                class="message-textarea"
                rows="6"
                style="width: 100%;"
            />

            <template #modal-footer>
                <b-button
                    variant="primary"
                    @click="onClickSendAdminEmail"
                >
                    Send
                </b-button>

                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('send-admin-email')"
                >
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { DirectUpload } from "@rails/activestorage"
import countryRegionData from "country-region-data/data"
import { blobImage } from "api/blob-image"
import { getFirm, saveFirm, deleteFirm } from "api/admin/firms"
import { deepCopy } from "mixins/deep-copy"
import { mapActions, mapGetters } from "vuex"

export default {
    data () {
        const baseURL = document.querySelector("meta[name=\"base-url\"]").content
        const directUploadPath = document.querySelector("meta[name=\"direct-upload-path\"]").content
        const directUploadUrl = directUploadPath.startsWith("/")
            ? baseURL + directUploadPath.slice(1)
            : baseURL + directUploadPath

        const attributeDefinitions = [
            { name: "profile_state", simple: true, default: "submitted" },
            { name: "tier", simple: true, default: 3 },
            { name: "company_name", simple: true, default: "" },
            { name: "country", simple: true, default: null },
            { name: "state", simple: true, default: null },
            { name: "zip", simple: true, default: null },
            { name: "city", simple: true, default: null },
            { name: "address", simple: true, default: null },
            { name: "email", simple: true, default: null },
            { name: "phone", simple: true, default: null },
            { name: "site", simple: true, default: null },
            { name: "profile_path", simple: false, default: null },
            { name: "firm_services", simple: false, default: () => [] },
            { name: "firm_specializations", simple: false, default: () => [] },
            { name: "images", simple: false, default: () => [] },
            { name: "job_opportunities", simple: true, default: false},
            { name: "job_opportunities_url", simple: true, default: ""},
            { name: "leed_member", simple: true, default: false},
            { name: "leed_member_name", simple: true, default: ""},
            // { name: "approved_at", simple: true, default: new Date },
            // { name: "keyword_list", simple: true, default: () => [] },
            // { name: "employees", simple: true, default: null },
            // { name: "offices", simple: true, default: null },
            // { name: "established", simple: true, default: null },
        ]

        const attributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = typeof info.default === "function" ? info.default() : info.default
            return hash
        }, {})

        const originalAttributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = typeof info.default === "function" ? info.default() : info.default
            return hash
        }, {})
        attributes.profile = undefined
        attributes.profile_picker = null
        // console.log(attributes)

        const attributeErrors = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = []
            return hash
        }, {})

        return {
            attributeDefinitions,
            attributes,
            attributeErrors,
            directUploadUrl,
            // internalImageId: 0,
            originalAttributes,
            currentService: null,
            currentSpecialization: null,
            imageKindOptions: [
                { value: "cover", text: "Cover" },
                { value: "logo", text: "Logo" },
                // { value: "profile", text: "Profile" },
                // { value: "carousel", text: "Carousel" },
            ],
            imagePicker: null,
            tierOptions: [
                { value: 1, text: "1 - Premium" },
                { value: 2, text: "2 - Standard" },
                { value: 3, text: "3 - Basic" },
            ],
            profileStateOptions: [                
                {value: 'application', text: "Application"},
                {value: 'pre-approved', text: "Pre-approved"},
                {value: 'submitted', text: "Submitted"},
                {value: 'published', text: "Published"},
                {value: 'denied', text: "Denied"}
                // { value: "first-saved", text: "Pre-approval Saved" },
                // { value: "first-submitted", text: "Pre-approval Submitted" },
                // { value: "first-approved", text: "Pre-approval Approved" },
                // { value: "second-saved", text: "Basic Submission Saved" },
                // { value: "second-submitted", text: "Basic Submission Submitted" },
                // { value: "second-approved", text: "Basic Submission Approved" },
                // { value: "payment-pending", text: "Payment Pending" },
                // { value: "third-saved", text: "Full Submission Saved" },
                // { value: "third-submitted", text: "Full Submission Submitted" },
                // // { value: "third-approved", text: "Full Submission Approved" },
                // { value: "published", text: "Published" },
                // { value: "suspended", text: "Suspended" }
            ],
            adminEmailMessage: '',
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
        ...mapGetters("firms", ["services", "specializations"]),

        specializationOptions () {
            const idToSpecialization = {}
            const specializations = this.specializations.map(
                s => Object.assign({}, s))
            specializations.forEach(spec => {
                idToSpecialization[spec.id] = spec
            })
            specializations.forEach(spec => {
                if (spec.parent_id) {
                    const parent = idToSpecialization[spec.parent_id]
                    if (parent) {
                        if (!parent.children) {
                            parent.children = []
                        }
                        parent.children.push(spec)
                    }
                }
            })
            return specializations.filter(spec => !spec.parent_id).map(this.specializationToOption)
        },

        serviceOptions () {
            const idToService = {}
            const services = this.services.map(
                s => Object.assign({}, s))
            services.forEach(spec => {
                idToService[spec.id] = spec
            })
            services.forEach(spec => {
                if (spec.parent_id) {
                    const parent = idToService[spec.parent_id]
                    if (parent) {
                        if (!parent.children) {
                            parent.children = []
                        }
                        parent.children.push(spec)
                    }
                }
            })
            return services.filter(spec => !spec.parent_id).map(this.serviceToOption)
        },

        countries () {
            return [
                {value: "US", text: "United States"},
                {value: "CA", text: "Canada"}
                ]
            // return countryRegionData.map(country => {
            //     return {
            //         value: country.countryShortCode,
            //         text: country.countryName
            //     }
            // })
        },

        states () {
            const country = this.attributes.country
            let prohibited_states = ["American Samoa","Micronesia","Guam","Marshall Islands","Palau","Puerto Rico","Virgin Islands","Armed Forces Americas","Armed Forces Europe","Armed Forces Pacific","Armed Forces Europe, Canada, Africa and Middle East"]
            if (country) {
                const entry = countryRegionData.find(
                    entry => entry.countryShortCode === country)
                if (entry) {
                    return entry.regions.filter(f => !prohibited_states.includes(f.name) ).map(region => {
                        return { value: region.shortCode, text: region.name }
                    })
                }
            }
            return []
        },

        availServiceOptions () {
            const ids = this.attributes.firm_services.map(fs => fs.service.id)
            return this.services
                .filter(service => ids.includes(service.id))
                .map(this.serviceToOption)
        },

        availSpecializationOptions () {
            const ids = this.attributes.firm_specializations
                .map(fs => fs.specialization.id)
            return this.specializations
                .filter(specialization => ids.includes(specialization.id))
                .map(this.specializationToOption)
        },

        profileState () { return null },
        profileError () { return "" },
        profileStateState () { return null },
        profileStateError () { return "" },
        companyNameState () { return null },
        companyNameError () { return "" },
        countryState () { return null },
        countryError () { return "" },
        stateState () { return null },
        stateError () { return "" },
        zipState () { return null },
        zipError () { return "" },
        cityState () { return null },
        cityError () { return "" },
        addressState () { return null },
        addressError () { return "" },
        leedMemberState () { return null },
        leedMemberError () { return "" },
        leedMemberNameState () { return null },
        leedMemberNameError () { return "" },
        jobOpportunitiesState () { return null },
        jobOpportunitiesError () { return "" },
        jobOpportunitiesUrlState () { return null },
        jobOpportunitiesUrlError () { return "" },
        emailState () { return null },
        emailError () { return "" },
        phoneState () { return null },
        phoneError () { return "" },
        siteState () { return null },
        siteError () { return "" },
        // serviceState () { return null },
        // serviceError () { return "" },
        // specializationState () { return null },
        // specializationError () { return "" },
        // keywordListState () { return null },
        // keywordListError () { return "" },
        // employeesState () { return null },
        // employeesError () { return "" },
        // officesState () { return null },
        // officesError () { return "" },
        // establishedState () { return null },
        // establishedError () { return "" },
        tierState () { return null },
        tierError () { return "" },
    },

    watch: {
        ["attributes.country"] () {
            this.attributes.state = null
        },
    },

    mounted () {
        this.loadServices()
        this.loadSpecializations()
        this.onLoad()
    },

    methods: {
        ...mapActions("firms", ["loadServices", "loadSpecializations"]),
        
        onInputSpecialization (value) {
            let findUsedSpecializationIndex = []
            this.attributes.firm_specializations.map((m,i) =>{
                if(!m.id && !this.attributes.images.find(f => f.specialization_id == m.specialization_id)){
                    findUsedSpecializationIndex.push(i);
                }
            });
            findUsedSpecializationIndex.map(m=>{
                this.attributes.firm_specializations.splice(m,1);
            })
            if(!this.attributes.firm_specializations.find(f=>f.specialization_id == value)){
                this.currentSpecialization = value;
                this.onAddSpecialization();
            }
        },

        onInputService (value) {
            let findUsedServiceIndex = []
            this.attributes.firm_services.map((m,i) =>{
                if(!m.id && !this.attributes.images.find(f => f.service_id == m.service_id)){
                    findUsedServiceIndex.push(i);
                }
            });
            findUsedServiceIndex.map(m=>{
                this.attributes.firm_services.splice(m,1);
            })
            if(!this.attributes.firm_services.find(f=>f.service_id == value)){
                this.currentService = value;
                this.onAddService();
            }
        },

        onClickDelete () {
            // const message = `Are you sure you want to delete “${ this.originalAttributes.company_name }”? This firm will be deleted immediately. You can't undo this action.`
            // const message = `Are you sure you want to DELETE this profile?`;
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#dc3545;">DELETE</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteFirm(this.adminToken, this.originalAttributes)
                            .then(() => {
                                // this.$bvToast.toast("Firm deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-firms" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        updateProfileState (profileState) {
            const record = {
                id: this.originalAttributes.id,
                profile_state: profileState
            }
            saveFirm(this.adminToken, record)
            .then(result => {
                Object.assign(this.originalAttributes, result)
                this.$bvToast.toast("Succeeded!", {
                    title: "Save status",
                    variant: "success",
                    autoHideDelay: 5000
                })
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unable to save",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickApprove () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#28a745;">APPROVE</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "success",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    let newProfileState = null
                    switch (this.originalAttributes.profile_state) {
                    case "application":
                        newProfileState = "pre-approved"
                        break
                    case "pre-approved":
                        newProfileState = "submitted"
                        break
                    case "submitted":
                        newProfileState = "published"
                        break
                    default:
                        break
                    }
                    if (newProfileState) {
                        this.updateProfileState(newProfileState)
                    }
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickDeny () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#dc3545;">DENY</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    let newProfileState = null
                    switch (this.originalAttributes.profile_state) {
                    case "pre-approved":
                        newProfileState = "application"
                        break
                    case "submitted":
                        newProfileState = "pre-approved"
                        break
                    default:
                        break
                    }
                    if (newProfileState) {
                        this.updateProfileState(newProfileState)
                    }
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickSuspend () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#ffc107;">SUSPEND</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                hideHeader: true,
                okTitle: "Yes",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    this.updateProfileState("suspended")
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        serviceToOption (service) {
            const title = service.parent
                ? `${service.parent.name}: ${service.name}` : service.name
            if (service.children) {
                const options = service.children.map(this.serviceToOption)
                options.splice(0, 0, { value: Number(service.id), text: `All ${service.name}` })
                return { label: title, options }
            } else {
                return { value: Number(service.id), text: title }
            }
        },

        specializationToOption (specialization) {
            const title = specialization.parent
                ? `${specialization.parent.category_name || specialization.parent.name}: ${specialization.name}`
                : specialization.name
            if (specialization.children) {
                const options = specialization.children.map(this.specializationToOption)
                options.splice(0, 0, { value: Number(specialization.id), text: `All ${specialization.name}` })
                return { label: title, options }
            } else {
                return { value: Number(specialization.id), text: title }
            }
        },

        uploadImage (file) {
            if (file === null) {
                return Promise.reject(new Error("File attribute is empty"))
            }

            return new Promise((resolve, reject) => {
                const upload = new DirectUpload(file, this.directUploadUrl)
                upload.create((error, blob) => {
                    if (error) {
                        reject(error)
                    } else {
                        const blobId = blob.signed_id
                        blobImage(blobId)
                            .then(path => resolve({ image: blobId, path }))
                            .catch(error => reject(error))
                    }
                })
            })
        },

        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getFirm(this.adminToken, { id })
                    .then(record => {
                        // console.log("*** Loaded")
                        // console.dir(record)
                        this.applyRecord(record)
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unable to load",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        applyRecord (record) {
            this.originalAttributes = deepCopy(record)
            this.attributeDefinitions.forEach(definition => {
                const name = definition.name
                let value = deepCopy(record[name])
                // if (name === "keyword_list") {
                //     value = (value || "").split(/, */)
                // }
                this.attributes[name] = value
                this.attributeErrors[name].splice(0, this.attributeErrors[name].length)
            })

            this.$nextTick(() => {
                this.attributes["state"] = record.state
                // this.updateAvailableProductTypes()
            })
        },

        onAddSpecialization () {
            if (this.currentSpecialization) {
                const specialization = this.specializations.find(
                    s => s.id === this.currentSpecialization)
                if (!this.attributes.firm_specializations.find(fs => fs.specialization.id === specialization.id)) {
                    this.attributes.firm_specializations.push({
                        id: undefined,
                        specialization_id: specialization.id,
                        specialization: deepCopy(specialization),
                    })
                }
            }
        },

        onRemoveSpecialization (firmSpecialization) {
            const index = this.attributes.firm_specializations.indexOf(firmSpecialization)
            if (index >= 0) {
                this.attributes.firm_specializations.splice(index, 1)
            }
            this.attributes.images.forEach(image => {
                if (image.specialization_id === firmSpecialization.id) {
                    image.specialization_id = null
                }
            })
        },

        onAddService () {
            if (this.currentService) {
                const service = this.services.find(
                    s => s.id === this.currentService)
                if (!this.attributes.firm_services.find(fs => fs.service.id === service.id)) {
                    this.attributes.firm_services.push({
                        id: undefined,
                        service_id: service.id,
                        service: deepCopy(service),
                    })
                }
            }
        },

        onRemoveService (firmService) {
            const index = this.attributes.firm_services.indexOf(firmService)
            if (index >= 0) {
                this.attributes.firm_services.splice(index, 1)
            }
            this.attributes.images.forEach(image => {
                if (image.service_id === firmService.id) {
                    image.service_id = null
                }
            })
        },

        onRemoveImage (firmImage) {
            const index = this.attributes.images.indexOf(firmImage)
            if (index >= 0) {
                this.attributes.images.splice(index, 1)
            }
        },

        onSave () {
            let record = {}
            this.attributeDefinitions.forEach(definition => {
                if (definition.simple) {
                    const name = definition.name
                    if ((!this.originalAttributes && this.attributes[name] !== definition.default) ||
                        (this.originalAttributes && this.originalAttributes[name] !== this.attributes[name])) {
                        record[name] = this.attributes[name]
                    }
                }
            })

            // Firm specializations
            record.firm_specializations_attributes = []
            this.attributes.firm_specializations.forEach(firmSpecialization => {
                record.firm_specializations_attributes.push({
                    id: firmSpecialization.id || undefined,
                    specialization_id: firmSpecialization.specialization_id,
                })
            })
            this.originalAttributes.firm_specializations.forEach(firmSpecialization => {
                const exists = this.attributes.firm_specializations.find(fs =>
                    fs.specialization.id === firmSpecialization.specialization.id)
                if (!exists) {
                    record.firm_specializations_attributes.push({
                        id: firmSpecialization.id,
                        _destroy: true,
                    })
                }
            })
            if (record.firm_specializations_attributes.length === 0) {
                delete record.firm_specializations_attributes
            }

            // Firm services
            record.firm_services_attributes = []
            this.attributes.firm_services.forEach(firmService => {
                record.firm_services_attributes.push({
                    id: firmService.id || undefined,
                    service_id: firmService.service_id,
                })
            })
            this.originalAttributes.firm_services.forEach(firmService => {
                const exists = this.attributes.firm_services.find(fs =>
                    fs.service.id === firmService.service.id)
                if (!exists) {
                    record.firm_services_attributes.push({
                        id: firmService.id,
                        _destroy: true,
                    })
                }
            })
            if (record.firm_services_attributes.length === 0) {
                delete record.firm_services_attributes
            }

            // Firm images
            record.images_attributes = []
            this.attributes.images.forEach(firmImage => {
                record.images_attributes.push({
                    id: firmImage.id || undefined,
                    kind: firmImage.kind,
                    image: firmImage.image || undefined,
                    caption: firmImage.caption,
                    position: firmImage.kind === "cover" ? firmImage.position : null,
                    service_id: firmImage.kind === "cover" ? firmImage.service_id || undefined : null,
                    specialization_id: firmImage.kind === "cover" ? firmImage.specialization_id || undefined : null,
                })
            })
            this.originalAttributes.images.forEach(firmImage => {
                const exists = this.attributes.images.find(img =>
                    img.id === firmImage.id)
                if (!exists) {
                    record.images_attributes.push({
                        id: firmImage.id,
                        _destroy: true,
                    })
                }
            })
            if (record.images_attributes.length === 0) {
                delete record.images_attributes
            }

            // record.keyword_list = (this.attributes.keyword_list || []).join(", ")

            console.log("Record to save:")
            console.dir(record)

            if (Object.keys(record).length === 0) {
                this.$bvToast.toast("Nothing to save!", {
                    title: "Save status",
                    variant: "warning",
                    autoHideDelay: 5000
                })
                return
            }

            if (this.originalAttributes) {
                record.id = this.originalAttributes.id
            }

            saveFirm(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.applyRecord(newRecord)
                    if (isNewRecord) {
                        this.$router.replace({
                            name: "admin-edit-firm",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onImageUpload (images) {
            if (Array.isArray(images)) {
                images.forEach(image => this.uploadProfileImage(image))
            } else {
                this.uploadProfileImage(images)
            }
            this.$nextTick(() => {
                this.imagePicker = null
            })
        },

        uploadProfileImage (imageFile) {
            this.uploadImage(imageFile)
                .then(({ image, path }) => {
                    this.attributes.images.push({
                        id: undefined,
                        kind: "cover",
                        caption: "",
                        position: null,
                        image: image,
                        image_path: path,
                        // image_picker: null,
                        service_id: null,
                        specialization_id: null,
                    })
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })                    
                })
        },

        onClickEmailAdmin() {
            this.$bvModal.show('send-admin-email')
        },

        async onClickSendAdminEmail() {
            try {
                const response = await fetch(`/api/v1/admin/firms/${this.$route.params.id}/send_admin_email`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ message: this.adminEmailMessage })
                });

                if (response.ok) {
                  this.adminEmailMessage = ''
                  this.$bvModal.hide('send-admin-email')
                } else {
                  alert('Message not delivered')  
                }
            } catch (error) {
                console.log(error)
                alert('Message not delivered')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.service-image-picker,
.specialization-image-picker {
    width: 40%;
}
</style>
