export const DESCRIPTIONS = {
    architect: "Architect",
    architectural_illustrator: "Architectural Illustrator",
    architectural_photographer: "Architectural Photographer",
    art_consultant: "Art Consultant",
    building_contractor: "Building Contractor",
    building_developer: "Building Developer",
    building_manager: "Building Manager",
    building_tradesperson: "Building Tradesperson",
    computer_professional: "Computer Professional",
    consumer: "Consumer",
    educator: "Educator",
    engineer: "Engineer",
    government_employee: "Government Employee",
    interior_designer: "Interior Designer",
    landscape_architect: "Landscape Architect/Designer",
    lighting_designer: "Lighting Designer",
    manufacturer: "Manufacturer",
    media_professional: "Media Professional",
    retailer_distributor: "Retailer/Distributor",
    specifier: "Specifier",
    student: "Student",
    urban_planner: "Urban Planner",
    other: "Other",
}

export const descriptionOptions = [
    { value: "architect", text: "Architect" },
    { value: "architectural_illustrator", text: "Architectural Illustrator" },
    { value: "architectural_photographer", text: "Architectural Photographer" },
    { value: "art_consultant", text: "Art Consultant" },
    { value: "building_contractor", text: "Building Contractor" },
    { value: "building_developer", text: "Building Developer" },
    { value: "building_manager", text: "Building Manager" },
    { value: "building_tradesperson", text: "Building Tradesperson" },
    { value: "computer_professional", text: "Computer Professional" },
    { value: "consumer", text: "Consumer" },
    { value: "educator", text: "Educator" },
    { value: "engineer", text: "Engineer" },
    { value: "government_employee", text: "Government Employee" },
    { value: "interior_designer", text: "Interior Designer" },
    { value: "landscape_architect", text: "Landscape Architect/Designer" },
    { value: "lighting_designer", text: "Lighting Designer" },
    { value: "manufacturer", text: "Manufacturer" },
    { value: "media_professional", text: "Media Professional" },
    { value: "retailer_distributor", text: "Retailer/Distributor" },
    { value: "specifier", text: "Specifier" },
    { value: "student", text: "Student" },
    { value: "urban_planner", text: "Urban Planner" },
    { value: "other", text: "Other" },
]
