import Vue from "vue"
import countryRegionData from "country-region-data/data"
// this.$options.filters.amount(this.count)
Vue.filter("country", (countryCode) => {
    if (countryCode && countryCode.length > 2) { countryCode = countryCode.slice(0, 2) }
    const country = countryCode && countryRegionData.find(
        country => country.countryShortCode === countryCode)
    return country ? country.countryName : countryCode
})

Vue.filter("region", (regionCode, countryCode) => {
    if (countryCode && countryCode.length > 2) { countryCode = countryCode.slice(0, 2) }
    const country = countryCode && countryRegionData.find(
        entry => entry.countryShortCode === countryCode)
    const region = country && country.regions.find(
        region => region.shortCode === regionCode)
    return region ? region.name : regionCode
})

Vue.filter("unit", (territorialUnit) => {
    var splitStr = ("" + territorialUnit).toLowerCase().split(/ +/)
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
})


const countries = [
        {
          value: "us",
          text: "United States",
          code: "united-states",
          states: [
          { value: "ak", text: "Alaska", code: 'alaska'},
          { value: "az", text: "Arizona", code: 'arizona'},
          { value: "ar", text: "Arkansas", code: 'arkansas'},
          { value: "ca", text: "California", code: 'california'},
          { value: "co", text: "Colorado", code: 'colorado'},
          { value: "ct", text: "Connecticut", code: 'connecticut'},
          { value: "de", text: "Delaware", code: 'delaware'},
          { value: "fl", text: "Florida", code: 'florida'},
          { value: "ga", text: "Georgia", code: 'georgia'},
          { value: "hi", text: "Hawaii", code: 'hawaii'},
          { value: "id", text: "Idaho", code: 'idaho'},
          { value: "il", text: "Illinois", code: 'illinois'},
          { value: "in", text: "Indiana", code: 'indiana'},
          { value: "ia", text: "Iowa", code: 'iowa'},
          { value: "ks", text: "Kansas", code: 'kansas'},
          { value: "ky", text: "Kentucky", code: 'kentucky'},
          { value: "la", text: "Louisiana", code: 'louisiana'},
          { value: "me", text: "Maine", code: 'maine'},
          { value: "md", text: "Maryland", code: 'maryland'},
          { value: "ma", text: "Massachusetts", code: 'massachusetts'},
          { value: "mi", text: "Michigan", code: 'michigan'},
          { value: "mn", text: "Minnesota", code: 'minnesota'},
          { value: "ms", text: "Mississippi", code: 'mississippi'},
          { value: "mo", text: "Missouri", code: 'missouri'},
          { value: "mt", text: "Montana", code: 'montana'},
          { value: "ne", text: "Nebraska", code: 'nebraska'},
          { value: "nv", text: "Nevada", code: 'nevada'},
          { value: "nh", text: "New Hampshire", code: 'new-hampshire'},
          { value: "nj", text: "New Jersey", code: 'new-jersey'},
          { value: "nm", text: "New Mexico", code: 'new-mexico'},
          { value: "ny", text: "New York", code: 'new-york'},
          { value: "nc", text: "North Carolina", code: 'north-carolina'},
          { value: "nd", text: "North Dakota", code: 'north-dakota'},
          { value: "oh", text: "Ohio", code: 'ohio'},
          { value: "ok", text: "Oklahoma", code: 'oklahoma'},
          { value: "or", text: "Oregon", code: 'oregon'},
          { value: "pa", text: "Pennsylvania", code: 'pennsylvania'},
          { value: "ri", text: "Rhode Island", code: 'rhode-island'},
          { value: "sc", text: "South Carolina", code: 'south-carolina'},
          { value: "sd", text: "South Dakota", code: 'south-dakota'},
          { value: "tn", text: "Tennessee", code: 'tennessee'},
          { value: "tx", text: "Texas", code: 'texas'},
          { value: "ut", text: "Utah", code: 'utah'},
          { value: "vt", text: "Vermont", code: 'vermont'},
          { value: "va", text: "Virginia", code: 'virginia'},
          { value: "wa", text: "Washington", code: 'washington'},
          { value: "wv", text: "West Virginia", code: 'west-virginia'},
          { value: "wi", text: "Wisconsin", code: 'wisconsin'},
          { value: "wy", text: "Wyoming", code: 'wyoming' }
        ]
        },
        {
          value: "ca",
          text: "Canada",
          code: "canada",
          states: [
          { value: "ab", text: "Alberta", code: 'alberta'},
          { value: "bc", text: "British Columbia", code: 'british-columbia'},
          { value: "mb", text: "Manitoba", code: 'manitoba'},
          { value: "nb", text: "New Brunswick", code: 'new-brunswick'},
          { value: "nl", text: "Newfoundland", code: 'newfoundland'},
          { value: "nt", text: "Northwest Territories", code: 'northwest-territories'},
          { value: "ns", text: "Nova Scotia", code: 'nova-scotia'},
          { value: "nu", text: "Nunavut", code: 'nunavut'},
          { value: "on", text: "Ontario", code: 'ontario'},
          { value: "pe", text: "Prince Edward Island", code: 'prince-edward-island'},
          { value: "qc", text: "Quebec", code: 'quebec'},
          { value: "sk", text: "Saskatchewan", code: 'saskatchewan'},
          { value: "yt", text: "Yukon Territory", code: 'yukon-territory' }
        ]
        },
        {
          value: "us,ca",
          text: "North America",
          code: "north-america",
          states: [
          { value: "ak", text: "Alaska", code: 'alaska'},
          { value: "az", text: "Arizona", code: 'arizona'},
          { value: "ar", text: "Arkansas", code: 'arkansas'},
          { value: "ca", text: "California", code: 'california'},
          { value: "co", text: "Colorado", code: 'colorado'},
          { value: "ct", text: "Connecticut", code: 'connecticut'},
          { value: "de", text: "Delaware", code: 'delaware'},
          { value: "fl", text: "Florida", code: 'florida'},
          { value: "ga", text: "Georgia", code: 'georgia'},
          { value: "hi", text: "Hawaii", code: 'hawaii'},
          { value: "id", text: "Idaho", code: 'idaho'},
          { value: "il", text: "Illinois", code: 'illinois'},
          { value: "in", text: "Indiana", code: 'indiana'},
          { value: "ia", text: "Iowa", code: 'iowa'},
          { value: "ks", text: "Kansas", code: 'kansas'},
          { value: "ky", text: "Kentucky", code: 'kentucky'},
          { value: "la", text: "Louisiana", code: 'louisiana'},
          { value: "me", text: "Maine", code: 'maine'},
          { value: "md", text: "Maryland", code: 'maryland'},
          { value: "ma", text: "Massachusetts", code: 'massachusetts'},
          { value: "mi", text: "Michigan", code: 'michigan'},
          { value: "mn", text: "Minnesota", code: 'minnesota'},
          { value: "ms", text: "Mississippi", code: 'mississippi'},
          { value: "mo", text: "Missouri", code: 'missouri'},
          { value: "mt", text: "Montana", code: 'montana'},
          { value: "ne", text: "Nebraska", code: 'nebraska'},
          { value: "nv", text: "Nevada", code: 'nevada'},
          { value: "nh", text: "New Hampshire", code: 'new-hampshire'},
          { value: "nj", text: "New Jersey", code: 'new-jersey'},
          { value: "nm", text: "New Mexico", code: 'new-mexico'},
          { value: "ny", text: "New York", code: 'new-york'},
          { value: "nc", text: "North Carolina", code: 'north-carolina'},
          { value: "nd", text: "North Dakota", code: 'north-dakota'},
          { value: "oh", text: "Ohio", code: 'ohio'},
          { value: "ok", text: "Oklahoma", code: 'oklahoma'},
          { value: "or", text: "Oregon", code: 'oregon'},
          { value: "pa", text: "Pennsylvania", code: 'pennsylvania'},
          { value: "ri", text: "Rhode Island", code: 'rhode-island'},
          { value: "sc", text: "South Carolina", code: 'south-carolina'},
          { value: "sd", text: "South Dakota", code: 'south-dakota'},
          { value: "tn", text: "Tennessee", code: 'tennessee'},
          { value: "tx", text: "Texas", code: 'texas'},
          { value: "ut", text: "Utah", code: 'utah'},
          { value: "vt", text: "Vermont", code: 'vermont'},
          { value: "va", text: "Virginia", code: 'virginia'},
          { value: "wa", text: "Washington", code: 'washington'},
          { value: "wv", text: "West Virginia", code: 'west-virginia'},
          { value: "wi", text: "Wisconsin", code: 'wisconsin'},
          { value: "wy", text: "Wyoming", code: 'wyoming' },
          { value: "ab", text: "Alberta", code: 'alberta'},
          { value: "bc", text: "British Columbia", code: 'british-columbia'},
          { value: "mb", text: "Manitoba", code: 'manitoba'},
          { value: "nb", text: "New Brunswick", code: 'new-brunswick'},
          { value: "nl", text: "Newfoundland", code: 'newfoundland'},
          { value: "nt", text: "Northwest Territories", code: 'northwest-territories'},
          { value: "ns", text: "Nova Scotia", code: 'nova-scotia'},
          { value: "nu", text: "Nunavut", code: 'nunavut'},
          { value: "on", text: "Ontario", code: 'ontario'},
          { value: "pe", text: "Prince Edward Island", code: 'prince-edward-island'},
          { value: "qc", text: "Quebec", code: 'quebec'},
          { value: "sk", text: "Saskatchewan", code: 'saskatchewan'},
          { value: "yt", text: "Yukon Territory", code: 'yukon-territory' }
        ]
        }
      ]


Vue.filter("countryShortCode", (countrySC) => {
    if (countrySC && countrySC.length > 2) { countrySC = countrySC.slice(0, 2) }
    const country = countrySC && countries.find(country => country.value === countrySC)
    return country ? country : countrySC
})

Vue.filter("countrySlugCode", (countrySlug, stateSlug = null) => {
    const country = countrySlug && countries.find(country => country.code === countrySlug)
    if(stateSlug){
        const state = country.states.find(state => state.code === stateSlug)
        console.log('state state :', state)
        return country ? state : stateSlug
    }else{
        return country ? country : countrySlug   
    }
})