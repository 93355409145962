export const inGroupsOf = (array, number, fillWith = null) => {
    if (number <= 0) {
        throw new Error(`Group size must be a positive integer, was ${number}`)
    }
    let collection
    if (fillWith === false) {
        collection = array
    } else {
        const length = (number - array.length % number) % number
        collection = [...array, ...Array.from({ length }, () => fillWith)]
    }
    const count = ~~((collection.length + number - 1) / number)
    return Array.from({ length: count }, (_, i) => {
        return collection.slice(i * number, (i + 1) * number)
    })
}

export const inGroups = (array, number, fillWith = null) => {
    if (number <= 0) {
        throw new Error(`Group size must be a positive integer, was ${number}`)
    }
    const modulo = array.length % number
    const division = (array.length - modulo) / number
    let groups = []
    let start = 0
    for (let index = 0; index < number; index++) {
        const length = division + (modulo > 0 && modulo > index ? 1 : 0)
        const lastGroup = array.slice(start, start + length)
        console.info(index, lastGroup)
        if (fillWith !== false && modulo > 0 && length === division) {
            lastGroup.push(fillWith)
        }
        groups.push(lastGroup)
        start += length
    }
    return groups
}

export const inColumnsOf = (array, number, fillWith = null) => {
    if (number <= 0) {
        throw new Error(`Group size must be a positive integer, was ${number}`)
    }
    const numberOfRows = Math.floor((array.length + number - 1) / number)
    let collection = []
    for (let row = 0; row < numberOfRows; row++) {
        collection[row] = []
        for (let col = 0; col < number; col++) {
            const index = col * numberOfRows + row
            if (index < array.length || fillWith !== false) {
                collection[row].push(array[index] || fillWith)
            }
        }
    }
    return collection
}

export const optionsToDictionary = (options, valueKey = "value", textKey = "text") => {
    return Object.assign({}, ...options.map(item => ({ [item[valueKey]]: item[textKey] })))
}

// sliceObject({ contry: "US", address: "Test" }, "country", "state", "city")
// => { contry: "US" }
export const sliceObject = (object, ...allowed) => {
    return Object.keys(object)
        .filter(key => allowed.includes(key))
        .reduce((result, key) => {
            result[key] = object[key]
            return result
        }, {})
}
