<template>
    <div class="content">
        <h1>{{['Members','Manufacturer Admins','Firm Admins','Users'][currentTab]}}</h1>

        <b-tabs
            v-model="currentTab"
            content-class="mt-3"
        >
            <b-tab :title="membersTab" />
            <b-tab :title="manufacturerAdminsTab" />
            <b-tab :title="firmAdminsTab" />
            <b-tab :title="usersTab" />
        </b-tabs>

        <b-row>
            <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-user' }"
                        >
                            New User
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-users' }"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                    aria-controls="index-table"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            @row-clicked="onClickRow"
        >
            <template #cell(id)="row">
                {{ `${getYearForDate(row.item.created_at)}-${row.item.id.toString().padStart(4, '100')}` }}
            </template>

            <template #cell(occupation)="row">
                {{ occupations[row.item.occupation] }}
            </template>

            <template #cell(plan_duration)="row" >
                <span style="text-transform: capitalize;">{{ row.item.plan_duration }}</span>
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>

            <template #cell(renewal_date)="row">
                {{ row.item.renewal_date | date }}
            </template>
        </b-table>

        <div class="d-flex justify-content-end">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="lastPage"
                :limit="7"
                :to="{ name: 'admin-users' }"
                :disabled="paginationDisable"
                @page-click="onPageClick"
                aria-controls="index-table"
            />
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getUsers } from "api/admin/users"
import { mapActions, mapGetters } from "vuex"
import { occupationOptions } from "enums/occupations"
import { optionsToDictionary } from "mixins/collection"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"
import moment from "moment"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            currentTab: 0,
            paginationDisable: false,
            items: [],
            totalItems: 0,
            itemsPerPage: 100,
            sortBy: "",
            sortDesc: null,
            tabCounts: {
                user_count: 0,
                members: 0,
                manufacturer_admins: 0,
                firm_admins: 0,
            },
            occupations: optionsToDictionary(occupationOptions),
            fields: [
                { key: "id", label: "ID", sortable: true, class: "column-left", thStyle: { width: "100px"} },
                { key: "email", label: "Email", sortable: true, class: "column-left", thStyle: { width: "200px"} },
                { key: "name", label: "Name", sortable: true, class: "column-left", thStyle: { width: "100px"} },
                { key: "company_name", label: "Company", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "country", label: "Country", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "state", label: "ST/PR", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                // { key: "site", label: "Site", sortable: true, class: "column-left" },
                // { key: "company_name", label: "Company Name", sortable: true, class: "column-left" },
                { key: "created_at", label: "Created", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "plan_duration", label: "Membership Type", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                { key: "renewal_date", label: "Renewal", sortable: true, class: "column-center", thStyle: { width: "100px"} },
                // { key: "updated_at", label: "Updated", sortable: true, class: "column-center" },
                { key: "phone", label: "Phone", sortable: false, class: "column-center", thStyle: { width: "100px"} },
            ],
            tabStates: ['members','manufacturer-admins','firm-admins','users']
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
        usersTab() {
            return `Users ( ${this.tabCounts.user_count} )`
        },
        membersTab() {
            return `Members ( ${this.tabCounts.members} )`
        },
        manufacturerAdminsTab() {
            return `Manufacturer Admins ( ${this.tabCounts.manufacturer_admins} )`
        },
        firmAdminsTab() {
            return `Firm Admins (${this.tabCounts.firm_admins})`
        },
    },

    watch: {
        "$route": "loadUsers",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },

        currentTab (newValue) {
            this.loadUsers();
        }
    },

    mounted () {
        this.loadUsers()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onSearch () {
            this.loadUsers();
        },

        getYearForDate (date) {
            return moment(date).format('YY');
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadUsers () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy
            this.sortDesc = this.$route.query.sortDesc
            this.paginationDisable = true;
            this.items = [];
            this.totalItems = 0;
            getUsers(this.adminToken, {
                page: this.currentPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                tab: this.tabStates[this.currentTab]
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data.data)
                    this.paginationDisable = false;
                    this.tabCounts = result.data.counts
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-show-user",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
</style>