export const manufacturerProfileState = {
    "first-saved":      "basic-submission-saved",
    "first-submitted":  "basic-submission-submitted",
    "first-approved":   "basic-submission-approved",
    "payment-pending":  "payment-pending",
    "second-saved":      "full-submission-saved",
    "second-submitted":  "full-submission-submitted",
    "second-approved":   "full-submission-approved",
    "published":        "published", // (should be same as second-approved and third-approved)
    "suspended":        "suspended"
}

export const firmProfileState = {
    "first-saved":      "pre-approval-saved",
    "first-submitted":  "pre-approval-submitted",
    "first-approved":   "pre-approval-approved",
    "second-saved":     "basic-submission-saved",
    "second-submitted": "basic-submission-submitted",
    "second-approved":  "basic-submission-approved",
    "payment-pending":  "payment-pending",
    "third-saved":      "full-submission-saved",
    "third-submitted":  "full-submission-submitted",
    "third-approved":   "full-submission-approved",
    "published":        "published", // (should be same as second-approved and third-approved)
    "suspended":        "suspended"
}
