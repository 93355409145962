<template>
    <b-modal
        :id="id"
        modal-class="subscribe-modal"
        hide-header
        hide-footer
        centered
        size="lg"
    >
        <b-container fluid>
            <a
                @click="onSubscription"
                style="cursor: pointer;"
            >
                <b-img
                    :src="require('assets/images/become-a-member.jpg')"
                    alt="Fluid-grow image"
                    fluid-grow
                />
            </a>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: value => [
                "becomeSubscriberImage",
                "saveFavorites",
                "requestInfo",
                "requestBid",
                "requestSample",
                "saveProfile"
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "become_subscriber_image_modal"
        }
    },

    data () {
        return {
            titles: {
                becomeSubscriberImage: "Member Benefits",
                saveFavorites: "Subscribe to Save Favorites",
                requestInfo: "Subscribe to Request Info",
                requestBid: "Subscribe to Request Bid",
                requestSample: "Subscribe to Request Sample",
                saveProfile: "You have to be a member to save profiles"
            },
            see_more_button_names: {
                becomeSubscriberImage: 'See Membership options',
                // becomeSubscriberImage: "Membership Information",
                saveFavorites: "See More Information",
                requestInfo: "See More Information",
                requestBid: "See More Information",
                requestSample: "See More Information",
                saveProfile: "Membership Information"
            }
        }
    },

    computed: {
        title () { return this.titles[this.type] },
        see_more_button_name () { return this.see_more_button_names[this.type] },
    },

    methods: {
        onLoginClicked () {
            this.$bvModal.hide(this.id)
            this.$bvModal.show("signin_modal")
        },

        onSubscription () {
            this.$bvModal.hide(this.id)
            this.$router.push({ name: "membership-options" })
        },

        onClose () {
            this.$bvModal.hide(this.id)
        },
    },
}
</script>

<style lang="scss">
    .subscribe-modal {
        .modal-dialog {
            max-width: 1024px;
        }

        .modal-body {
            padding: 0;
        }

        .container-fluid {
            padding: 0;
        }
    }
</style>

<style lang="scss" scoped>
    .close {
        margin-top: 10px;
    }

    h5 {
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 0.14px;
        color: #000;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 14px;
        li + li {
            margin-top: 8px;
        }
    }

    li {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
   }

    .accent {
        text-decoration: underline;
        font-weight: 700;
    }




    .subscription-price {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
    }

    .already-subscriber {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
        margin-top: 12px;
        margin-bottom: 30px;

        a {
            text-decoration: underline;
            color: #225a90;
        }
    }
</style>
