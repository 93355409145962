<template>
    <div class="content">
        <h1>Product Type #{{ record.id }}</h1>

        <b-button-toolbar
            aria-label="Actions"
            key-nav
            class="mb-3"
        >
            <b-button-group class="mx-1">
                <b-button
                    :to="{ name: 'admin-edit-product-type', params: { id: $route.params.id } }"
                >
                    Edit Product Type
                </b-button>
            </b-button-group>

            <b-button-group class="mx-1">
                <b-button
                    variant="danger"
                    @click="onClickDelete"
                >
                    Delete
                </b-button>
            </b-button-group>
        </b-button-toolbar>

        <show-columns
            :definitions="definitions"
            :record="record"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getCsiProductType, deleteCsiProductType } from "api/admin/csi-product-types"
import ShowColumns from "../shared/show-columns"

export default {
    components: { ShowColumns },

    data () {
        const definitions = [
            { name: "id", type: "number", default: null },
            { name: "name", type: "text", default: "" },
            { name: "csi_index_entry_code", label: "Csi Index Entry", type: "csi_index_entry", default: undefined },
            { name: "created_at", type: "datetime", default: null },
        ]
        const record = definitions.reduce((hash, info) => {
            hash[info.name] = typeof info.default === "function" ? info.default() : info.default
            if (info.type === "image") {
                hash[`${ info.name }_path`] = null
            }
            return hash
        }, {})
        record.id = this.$route.params.id
        return {
            definitions,
            record,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadRecord",
    },

    mounted () {
        this.loadRecord()
    },

    methods: {
        loadRecord () {
            getCsiProductType(this.adminToken, { id: this.record.id })
                .then(result => {
                    Object.assign(this.record, result)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to load record",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickDelete () {
            const message = `Are you sure you want to delete “${ this.record.id }”? This record will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteCsiProductType(this.adminToken, this.record)
                            .then(() => {
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-product-types" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        }
    }
}
</script>
