export default {
    data() {
        return {
            currentPage: 1,
            lastPage: 1
        }
    },
    methods: {
        onPageClick(bvEvt,page) {
            if (bvEvt.target.ariaLabel == "Go to next page") {
                bvEvt.preventDefault()
                if((this.lastPage - this.currentPage) < 5){
                    this.currentPage = this.lastPage
                }else{
                    this.currentPage += 5
                }
            } else if (bvEvt.target.ariaLabel == "Go to previous page") {
                bvEvt.preventDefault()
                if(this.currentPage < 5){
                    this.currentPage = 1
                }else{
                    this.currentPage -= 5
                }
            }
        }
    },
    watch: {
        currentPage(now,prev) {
            this.onSearch();
            window.scrollTo(0, 0)
        }
    }
}
