import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getCsiIndexEntries = (token, { page = 1, sortBy = "", sortDesc = false, type, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    if (type){
        params["type"] = type
    }
    return getRequest("/admin/csi_index_entries", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getCsiIndexEntry = (token, { id }) => {
    return getRequest(`/admin/csi_index_entries/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveCsiIndexEntry = (token, csiIndexEntry) => {
    if (csiIndexEntry.id) {
        let { id, ...csiIndexEntryWithoutId } = csiIndexEntry
        return patchRequest(`/admin/csi_index_entries/${ id }`, {
            csi_index_entry: csiIndexEntryWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/csi_index_entries", {
            csi_index_entry: csiIndexEntry
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteCsiIndexEntry = (token, csiIndexEntry) => {
    return deleteRequest(`/admin/csi_index_entries/${ csiIndexEntry.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
