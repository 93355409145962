import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getDesignProfessionals = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/design_professionals", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getDesignProfessional = (token, { id }) => {
    return getRequest(`/admin/design_professionals/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveDesignProfessional = (token, designProfessional) => {
    if (designProfessional.id) {
        let { id, ...designProfessionalWithoutId } = designProfessional
        return patchRequest(`/admin/design_professionals/${ id }`, {
            design_professional: designProfessionalWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/design_professionals", {
            design_professional: designProfessional
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteDesignProfessional = (token, designProfessional) => {
    return deleteRequest(`/admin/design_professionals/${ designProfessional.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
