import { csiIndexEntries, csiDivision, getCsiIndexEntry } from "api/csi-entries"
import { getCsiIndexEntries } from "api/manufacturers"

const EMPTY_CSI_ENTRY = {
    code: null,
    entryType: null,
    title: null,
    children: []
}

export default {
    namespaced: true,

    state: {
        selectedDivision: null,
        selectedDivisionChildren: [],
        
        primaryEntry: null,
        primaryEntries: [],
        csiDivisionSubDivisions: [],
        csiSubDivision: null,

        primaryItemEntry: null,
        primaryItemEntries: [],
        secondaryEntry: null,
        secondaryEntries: [],
        secondaryItemEntry: null,
        secondaryItemEntries: [],
        tertiaryEntry: null,
        tertiaryEntries: [],
        tertiaryItemEntry: null,
        tertiaryItemEntries: [],
        topDivisions: [],
        productType: null,
        productTypes: [],
        location: "",
        keywords: "",

        // NEW
        currentCode: undefined,
        isLoadingCurrentEntry: false,
        currentEntry: EMPTY_CSI_ENTRY,

        loadingCsiIndexEntriesPromise: null,
        csiIndexEntries: [],
    },

    getters: {
        selectedDivision: (state) => state.selectedDivision,
        topDivisions: (state) => state.topDivisions,
        selectedDivisionChildren: (state) => state.selectedDivisionChildren,
        
        // remove start
        primaryEntry: (state) => state.primaryEntry,
        primaryEntries: (state) => state.primaryEntries,
        csiDivisionSubDivisions: (state) => state.csiDivisionSubDivisions,
        csiSubDivision: (state) => state.csiSubDivision,

        primaryItemEntry: (state) => state.primaryItemEntry,
        primaryItemEntries: (state) => state.primaryItemEntries,
        secondaryEntry: (state) => state.secondaryEntry,
        secondaryEntries: (state) => state.secondaryEntries,
        secondaryItemEntry: (state) => state.secondaryItemEntry,
        secondaryItemEntries: (state) => state.secondaryItemEntries,
        tertiaryEntry: (state) => state.tertiaryEntry,
        tertiaryEntries: (state) => state.tertiaryEntries,
        tertiaryItemEntry: (state) => state.tertiaryItemEntry,
        tertiaryItemEntries: (state) => state.tertiaryItemEntries,
        // remove end

        productType: (state) => state.productType,
        productTypes: (state) => state.productTypes,
        location: (state) => state.location,
        keywords: (state) => state.keywords,

        // NEW
        currentEntry: (state) => state.currentEntry,
        csiIndexEntries: (state) => state.csiIndexEntries,
    },

    mutations: {
        setSelectedDivision (state, division) {
            state.selectedDivision = division
        },

        selectedDivisionChildren (state, children) {
            state.selectedDivisionChildren = children
        },

        setPrimaryEntries (state, entries) {
            state.primaryEntries = entries
                .filter(entry => entry.entry_type === "primary")
            state.primaryEntry = null

            state.primaryItemEntries = entries
                .filter(entry => entry.entry_type === "primary_item")
            state.primaryItemEntry = null
            state.secondaryEntries.splice(0, state.secondaryEntries.length)
            state.secondaryEntry = null
            state.secondaryItemEntries.splice(0, state.secondaryItemEntries.length)
            state.secondaryItemEntry = null
            state.tertiaryEntries.splice(0, state.tertiaryEntries.length)
            state.tertiaryEntry = null
            state.tertiaryItemEntries.splice(0, state.tertiaryItemEntries.length)
            state.tertiaryItemEntry = null
            // state.productTypes.splice(0, state.productTypes.length)
            // state.productType = null
        },

        setPrimaryEntry (state, primaryEntry) {
            state.primaryEntry = primaryEntry

            state.primaryItemEntries.splice(0, state.primaryItemEntries.length)
            state.primaryItemEntry = null
            state.secondaryEntries.splice(0, state.secondaryEntries.length)
            state.secondaryEntry = null
            state.secondaryItemEntries.splice(0, state.secondaryItemEntries.length)
            state.secondaryItemEntry = null
            state.tertiaryEntries.splice(0, state.tertiaryEntries.length)
            state.tertiaryEntry = null
            state.tertiaryItemEntries.splice(0, state.tertiaryItemEntries.length)
            state.tertiaryItemEntry = null
            // state.productTypes.splice(0, state.productTypes.length)
            // state.productType = null
        },

        setCsiDivisionSubDivisions (state, entries) {
            state.csiDivisionSubDivisions = entries
            // reset sub sub and sub sub sub
        },

        setCsiSubDivision (state, csiSubDivision) {
            state.csiSubDivision = csiSubDivision
        },

        setPrimarySubEntries (state, entries) {
            state.primaryItemEntries = entries
                .filter(entry => entry.entry_type === "primary_item")
            state.primaryItemEntry = null
            state.secondaryEntries = entries
                .filter(entry => entry.entry_type === "secondary")
            state.secondaryEntry = null
            state.secondaryItemEntries = entries
                .filter(entry => entry.entry_type === "secondary_item")
            state.secondaryItemEntry = null
            state.tertiaryEntries = entries
                .filter(entry => entry.entry_type === "tertiary")
            state.tertiaryEntry = null
            state.tertiaryItemEntries = entries
                .filter(entry => entry.entry_type === "tertiary_item")
            state.tertiaryItemEntry = null
            // state.productTypes = entries
            //     .filter(entry => entry.entry_type === "item")
            // state.productType = null
        },

        setSecondaryEntry (state, secondaryEntry) {
            state.secondaryEntry = secondaryEntry
            state.secondaryItemEntries.splice(0, state.secondaryItemEntries.length)
            state.secondaryItemEntry = null
            state.tertiaryEntries.splice(0, state.tertiaryEntries.length)
            state.tertiaryEntry = null
            state.tertiaryItemEntries.splice(0, state.tertiaryItemEntries.length)
            state.tertiaryItemEntry = null
            // state.productTypes.splice(0, state.productTypes.length)
            // state.productType = null
        },

        setSecondarySubEntries (state, entries) {
            state.secondaryItemEntries = entries
                .filter(entry => entry.entry_type === "secondary_item")
            state.secondaryItemEntry = null
            state.tertiaryEntries = entries
                .filter(entry => entry.entry_type === "tertiary")
            state.tertiaryEntry = null
            state.tertiaryItemEntries = entries
                .filter(entry => entry.entry_type === "tertiary_item")
            state.tertiaryItemEntry = null
            // state.productTypes = entries
            //     .filter(entry => entry.entry_type === "item")
            // state.productType = null
        },

        setTertiaryEntry (state, tertiaryEntry) {
            state.tertiaryEntry = tertiaryEntry
            state.tertiaryItemEntries.splice(0, state.tertiaryItemEntries.length)
            state.tertiaryItemEntry = null
            // state.productTypes.splice(0, state.productTypes.length)
            // state.productType = null
        },

        setTertiarySubEntries (state, entries) {
            state.tertiaryItemEntries = entries
                .filter(entry => entry.entry_type === "tertiary_item")
            state.tertiaryItemEntry = null
            // state.productTypes = entries
            //     .filter(entry => entry.entry_type === "item")
            // state.productType = null
        },
        
        setPrimaryItemEntry (state, itemEntry) {
            state.primaryItemEntry = itemEntry
        },
        
        setSecondaryItemEntry (state, itemEntry) {
            state.secondaryItemEntry = itemEntry
        },

        setTertiaryItemEntry (state, itemEntry) {
            state.tertiaryItemEntry = itemEntry
        },

        setProductType (state, productType) {
            state.productType = productType
        },

        setLocation (state, location) {
            state.location = location
        },

        setKeywords (state, keywords) {
            state.keywords = keywords
        },
        setTopDivisions (state, entries) {
            state.topDivisions = entries
        },

        // NEW

        setCurrentEntry (state, { code, entry = null }) {
            if (entry) {
                if (state.currentCode === code) {
                    console.log("LOADED, APPLY", entry)
                    state.isLoadingCurrentEntry = false
                    state.currentEntry = entry
                } else {
                    console.log("LOADED, SKIP")
                }
            } else if (state.currentCode !== code) {
                console.log("START LOADING", code)
                state.isLoadingCurrentEntry = true
                state.currentCode = code
            }
        },

        setLoadingCsiIndexEntriesPromise (state, promise) {
            state.loadingCsiIndexEntriesPromise = promise
        },

        setCsiIndexEntries (state, csiIndexEntries) {
            state.loadingCsiIndexEntriesPromise = null
            state.csiIndexEntries.splice(0, state.csiIndexEntries.length, ...csiIndexEntries)
        }
    },

    actions: {
        loadPrimaryEntries ({ commit }) {
            return csiIndexEntries()
                .then(entries => {
                    commit("setPrimaryEntries", entries)
                    return true
                })
        },

        loadTopDivisions ({ commit }) {
            return csiIndexEntries()
                .then(entries => {
                    commit("selectedDivisionChildren", entries)
                    commit("setTopDivisions", entries)
                    return true
                })
        },

        setSelectedDivision ({ commit }, division) {
            commit('setSelectedDivision', division)
            if (division) {
                return csiIndexEntries({ parentCode: division.code })
                    .then(children => {
                        commit("selectedDivisionChildren", children)
                        return true
                    })
            }
        },

        setSelectedDivisionByCode ({ commit }, code) {
            // todo get code from csi
            // const division = {code}
            csiDivision({code})
                .then(division => {
                    commit('setSelectedDivision', division)
                    if (division) {
                        return csiIndexEntries({ parentCode: division.code })
                            .then(children => {
                                commit("selectedDivisionChildren", children)
                                return true
                            })
                    }
                })
        },

        setPrimaryEntry ({ commit }, entry) {
            commit("setPrimaryEntry", entry)
            if (entry) {
                return csiIndexEntries({ parentCode: entry.code })
                    .then(entries => {
                        commit("setCsiDivisionSubDivisions", entries)
                        // commit("setPrimarySubEntries", entries)
                        return true
                    })
            }
        },

        setPrimaryAndSecondaryEntry ({ commit, state }, { primary, secondary }) {
            console.log('setPrimaryAndSecondaryEntry', { primary, secondary })
            const entry = state.primaryEntries.find(e => e.code === primary.code)
            if (entry) {
                commit("setPrimaryEntry", entry)
                return csiIndexEntries({ parentCode: entry.code })
                    .then(entries => {
                        commit("setCsiDivisionSubDivisions", entries)
                        // commit("setPrimarySubEntries", entries)
                        if (secondary) {
                            const subentry = entries.find(e => e.code === secondary.code)
                            if (subentry) {
                                commit("setSecondaryEntry", subentry)
                                return csiIndexEntries({ parentCode: subentry.code })
                                    .then(subentries => {
                                        commit("setSecondarySubEntries", subentries)
                                        return true
                                    })
                            }
                        }
                        return true
                    })
            }
        },

        setSecondaryEntry ({ commit }, entry) {
            commit("setSecondaryEntry", entry)
            if (entry) {
                return csiIndexEntries({ parentCode: entry.code })
                    .then(entries => {
                        commit("setSecondarySubEntries", entries)
                        return true
                    })
            }
        },

        setTertiaryEntry ({ commit }, entry) {
            commit("setTertiaryEntry", entry)
            if (entry) {
                return csiIndexEntries({ parentCode: entry.code })
                    .then(entries => {
                        commit("setTertiarySubEntries", entries)
                        return true
                    })
            }
        },

        setProductType ({ commit }, entry) {
            commit("setProductType", entry)
        },

        setLocation ({ commit }, location) {
            commit("setLocation", location)
        },

        setKeywords ({ commit }, keywords) {
            commit("setKeywords", keywords)
        },

        // NEW

        loadEntryByCode ({ commit, state }, code) {
            if (state.currentCode === code) {
                return Promise.resolve()
            }
            commit("setCurrentEntry", { code })
            getCsiIndexEntry({ code })
                .then(entry => {
                    commit("setCurrentEntry", { code, entry })
                })
                .catch(error => {
                    console.log(`Unable to load entry: ${ error.message }`)
                })
        },

        loadCsiIndexEntries ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.loadingCsiIndexEntriesPromise) {
                    return state.loadingCsiIndexEntriesPromise
                }
                if (state.csiIndexEntries.length !== 0) {
                    return Promise.resolve(state.csiIndexEntries)
                }
            }
            const promise = getCsiIndexEntries()
                .then(csiIndexEntries => {
                    commit("setCsiIndexEntries", csiIndexEntries)
                })
                .catch(error => {
                    commit("setLoadingCsiIndexEntriesPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setLoadingCsiIndexEntriesPromise", promise)
            return promise
        },
    }
}
