import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFavorites = (token, { page = 1, sortBy = "", sortDesc = false } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    return getRequest("/admin/favorites", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFavorite = (token, { id }) => {
    return getRequest(`/admin/favorites/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFavorite = (token, favorite) => {
    if (favorite.id) {
        let { id, ...favoriteWithoutId } = favorite
        return patchRequest(`/admin/favorites/${ id }`, {
            favorite: favoriteWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/favorites", {
            favorite: favorite
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFavorite = (token, favorite) => {
    return deleteRequest(`/admin/favorites/${ favorite.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
