<template>
    <section class="edit-columns">
        <span v-if="enableTitle">
            <b-row>
                <b-col md="4">
                    <h1 v-if="$route.params.id"> Edit {{title}} #{{ $route.params.id }} </h1>
                    <h1 v-else>New {{title}}</h1>
                </b-col>
                <b-col md="8">
                    <div class="d-flex justify-content-end">
                        <b-button
                            size="sm"
                            variant="primary"
                            class="px-5"
                            v-if="enableContinueLater"
                            @click="onSave('continue')"
                        >
                            Save And Continue Later
                        </b-button>
                        <b-button
                            size="sm"
                            variant="primary"
                            class="px-5 ml-2"
                            @click="onSave('save')"
                        >
                            Save
                        </b-button>
                        <b-button
                            v-if="enableDelete"
                            size="sm"
                            variant="danger"
                            class="px-5"
                            @click="$emit('delete')"
                        >
                            Delete
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </span>
        <b-row
            v-for="definition in definitions"
            :key="definition.name"
        >
            <b-col
                v-if="definition.type === 'boolean'"
                cols="12"
                md="12"
                class="mb-4"
            >
                <b-form-checkbox
                    :id="`${ definition.name }`"
                    v-model="attributes[definition.name]"
                    :value="true"
                    :unchecked-value="false"
                >
                    {{ definition.title || capitalize(definition.name) }}
                </b-form-checkbox>
            </b-col>

            <b-col
                v-else-if="definition.type === 'date'"
                cols="12"
                md="12"
            >
                <b-form-group
                    :id="`${ definition.name }_fieldset`"
                    :label="definition.title || capitalize(definition.name)"
                    :label-for="`${ definition.name }_date_picker`"
                    :invalid-feedback="attributeErrors[definition.name]"
                    :state="attributeStates[definition.name]"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-datepicker
                                :id="`${ definition.name }_date_picker`"
                                :state="attributeStates[definition.name]"
                                :value="attributes[definition.name] || ''"
                                :reset-button="true"
                                @input="attributes[definition.name] = $event || null"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>

            <b-col
                v-else-if="definition.type === 'datetime'"
                cols="12"
                md="12"
            >
                <b-form-group
                    :id="`${ definition.name }_fieldset`"
                    :label="definition.title || capitalize(definition.name)"
                    :label-for="`${ definition.name }_date_picker`"
                    :invalid-feedback="attributeErrors[definition.name]"
                    :state="attributeStates[definition.name]"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-datepicker
                                :id="`${ definition.name }_date_picker`"
                                :state="attributeStates[definition.name]"
                                :value="attributes[definition.name] ? moment(attributes[definition.name]).format('YYYY-MM-DD') : ''"
                                :reset-button="true"
                                @input="attributes[definition.name] = $event ? moment($event + 'T' + moment(attributes[definition.name] || undefined).format('HH:mm:ss')).toISOString() : null"
                            />
                        </b-col>

                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-timepicker
                                :id="`${ definition.name }_time_picker`"
                                :state="attributeStates[definition.name]"
                                :value="attributes[definition.name] ? moment(attributes[definition.name]).format('HH:mm:ss') : ''"
                                :reset-button="true"
                                :show-seconds="true"
                                @input="attributes[definition.name] = $event ? moment(moment(attributes[definition.name] || undefined).format('YYYY-MM-DD') + 'T' + $event).toISOString() : null"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>

            <b-col
                v-else-if="definition.type === 'duration'"
                cols="12"
                md="12"
            >
                <b-form-group
                    :id="`${ definition.name }_fieldset`"
                    :label="`${definition.title || capitalize(definition.name)} (hours : minutes : seconds)`"
                    :label-for="`${ definition.name }_minutes`"
                    :invalid-feedback="attributeErrors[definition.name]"
                    :state="attributeStates[definition.name]"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-input
                                v-maska :data-maska="`##:##:##`"
                                :id="`${ definition.name }`"
                                v-model="attributes[definition.name]"
                                :state="attributeStates[definition.name]"
                                type="text"
                                placeholder="Duration"
                                trim
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>



            <!-- <b-col
                v-else-if="definition.type === 'password'"
                cols="12"
                md="12"
            >
                <b-form-group
                    :id="`${ definition.name }_fieldset`"
                    :label="definition.title || capitalize(definition.name)"
                    :label-for="`${ definition.name }`"
                    :invalid-feedback="attributeErrors[definition.name]"
                    :state="attributeStates[definition.name]"
                >
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-input-group>
                                <b-form-input
                                    :id="`${ definition.name }`"
                                    v-model="attributes[definition.name]"
                                    :state="attributeStates[definition.name]"
                                    type="showPassword ? 'text' : 'password' "
                                    placeholder="Password"
                                    trim
                                />
                                <b-input-group-append>
                                    <b-button class="button" @click="togglePasswordVisibility" variant="outline-secondary" style="height: 100%" ><span class="icon is-small is-right">
                                          <b-icon :icon="showPassword ? 'eye-slash' : 'eye'"></b-icon></span></b-button>
                                </b-input-group-append>
                            </b-input-group>

                        </b-col>

                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-input-group>
                                <b-form-input
                                    :id="`${ definition.name }_confirmation`"
                                    v-model="attributes[`${ definition.name }_confirmation`]"
                                    :state="attributeStates[`${ definition.name }_confirmation`]"
                                    type="showPassword ? 'text' : 'password' "
                                    placeholder="Password Confirmation"
                                    trim
                                />
                                <b-input-group-append>
                                    <b-button class="button" @click="togglePasswordVisibility" variant="outline-secondary" style="height: 100%" ><span class="icon is-small is-right">
                                          <b-icon :icon="showPassword ? 'eye-slash' : 'eye'"></b-icon></span></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col> -->


            <b-col
                v-else-if="definition.type === 'attachment' || definition.type === 'image'"
                cols="12"
                md="12"
            >
                <b-row>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-img
                            v-if="definition.type === 'image'"
                            :src="attributes[`${ definition.name }_path`]"
                            class="mb-3"
                            alt="Image"
                            blank-color="#aaa"
                            thumbnail
                            rounded
                            fluid
                        />
                    </b-col>

                    <b-col cols="12" md="8">
                        <b-form-group
                            :id="`${ definition.name }_fieldset`"
                            :label="definition.title || capitalize(definition.name)"
                            :label-for="`${ definition.name }`"
                            :invalid-feedback="attributeErrors[definition.name]"
                            :state="attributeStates[definition.name]"
                        >
                            <b-form-file
                                :id="`${ definition.name }`"
                                :ref="`${ definition.name }_input`"
                                v-model="attributes[`${ definition.name }_picker`]"
                                :state="attributeStates[definition.name]"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                :accept="definition.type === 'image' ? 'image/jpeg, image/png, image/gif' : '*'"
                                @input="onUpload(definition.name, $event)"
                            />

                            <b-button
                                class="mr-2 mt-2"
                                @click="onReset(definition.name)"
                            >
                                Reset
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>

            <b-col
                v-else
                cols="12"
                md="12"
            >
                <b-form-group
                    :id="`${ definition.name }_fieldset`"
                    :label="definition.title || capitalize(definition.name)"
                    :label-for="`${ definition.name }`"
                    :invalid-feedback="attributeErrors[definition.name]"
                    :state="attributeStates[definition.name]"
                >
                    <b-form-textarea
                        v-if="definition.type === 'multiline'"
                        :id="`${ definition.name }`"
                        v-model="attributes[definition.name]"
                        :state="attributeStates[definition.name]"
                        rows="6"
                        max-rows="6"
                        trim
                    />

                    <b-form-select
                        v-else-if="definition.type === 'country'"
                        :id="`${ definition.name }`"
                        v-model="attributes[definition.name]"
                        :state="attributeStates[definition.name]"
                        :options="countries"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                Enter {{ definition.title || capitalize(definition.name) }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-select
                        v-else-if="definition.type === 'state'"
                        :id="`${ definition.name }`"
                        v-model="attributes[definition.name]"
                        :state="attributeStates[definition.name]"
                        :options="states"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                Enter {{ definition.title || capitalize(definition.name) }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-select
                        v-else-if="definition.type === 'options'"
                        :id="`${ definition.name }`"
                        v-model="attributes[definition.name]"
                        :state="attributeStates[definition.name]"
                        :options="definition.options"
                    >
                        <template #first>
                            <b-form-select-option :value="null">
                                Enter {{ definition.title || capitalize(definition.name) }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-input
                        v-else-if="['email', 'number', 'text', 'number', 'url', 'tel', 'color'].includes(definition.type)"
                        :id="`${ definition.name }`"
                        v-model="attributes[definition.name]"
                        :state="attributeStates[definition.name]"
                        :type="definition.type"
                        trim
                    />

                    <b-input-group v-else-if="definition.type === 'password' && definition.name === 'password'">
                        <b-form-input
                            :id="`${ definition.name }`"
                            v-model="attributes[definition.name]"
                            :state="attributeStates[definition.name]"
                            :type="showPassword ? 'text' : 'password' "
                            placeholder="Password"
                            trim
                        />
                        <b-input-group-append>
                            <b-button class="button" @click="togglePasswordVisibility('password')" variant="outline-secondary" style="height: 100%" ><span class="icon is-small is-right">
                                  <b-icon :icon="showPassword ? 'eye-slash' : 'eye'"></b-icon></span></b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-input-group v-else-if="definition.type === 'password' && definition.name === 'password_confirmation'">
                        <b-form-input
                            :id="`${ definition.name }`"
                            v-model="attributes[definition.name]"
                            :state="attributeStates[definition.name]"
                            :type="showPasswordConfirmation ? 'text' : 'password' "
                            placeholder="Password Confirmation"
                            trim
                        />
                        <b-input-group-append>
                            <b-button class="button" @click="togglePasswordVisibility('password_confirmation')" variant="outline-secondary" style="height: 100%" ><span class="icon is-small is-right">
                                  <b-icon :icon="showPasswordConfirmation ? 'eye-slash' : 'eye'"></b-icon></span></b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <multiselect
                        v-else-if="definition.type === 'csi_index_entry'"
                        :value="selectedCsiIndexEntry"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="csiIndexEntries"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingCsiIndexEntries"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextCsiIndexEntries"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindCsiIndexEntries"
                        @input="selectedCsiIndexEntry = $event; attributes[definition.name] = $event.value"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'primary_csi_index_entry_code'"
                        :value="selectedPrimaryTypeCodeCsiIndexEntry"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="primaryTypeCodeCsiIndexEntries"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingPrimaryTypeCodeCsiIndexEntries"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextPrimaryTypeCodeCsiIndexEntries"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindPrimaryTypeCodeCsiIndexEntries"
                        @input="selectedPrimaryTypeCodeCsiIndexEntry = $event; attributes[definition.name] = $event.value;"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'secondary_csi_index_entry_code'"
                        :value="selectedSecondaryTypeCodeCsiIndexEntry"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="secondaryTypeCodeCsiIndexEntries"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingSecondaryTypeCodeCsiIndexEntries"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextSecondaryTypeCodeCsiIndexEntries"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindSecondaryTypeCodeCsiIndexEntries"
                        @input="selectedSecondaryTypeCodeCsiIndexEntry = $event; attributes[definition.name] = $event.value;"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'tertiary_csi_index_entry_code'"
                        :value="selectedTertiaryTypeCodeCsiIndexEntry"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="tertiaryTypeCodeCsiIndexEntries"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingTertiaryTypeCodeCsiIndexEntries"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextTertiaryTypeCodeCsiIndexEntries"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindTertiaryTypeCsiIndexEntries"
                        @input="selectedTertiaryTypeCodeCsiIndexEntry = $event; attributes[definition.name] = $event.value"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'firm_profile'"
                        :value="selectedFirmProfile"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="firmProfiles"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingFirmProfiles"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextFirmProfiles"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindFirmProfiles"
                        @input="selectedFirmProfile = $event; attributes[definition.name] = $event.value"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'manufacturer_profile'"
                        :value="selectedManufacturerProfile"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="manufacturerProfiles"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingManufacturerProfiles"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextManufacturerProfiles"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindManufacturerProfiles"
                        @input="selectedManufacturerProfile = $event; attributes[definition.name] = $event.value"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <multiselect
                        v-else-if="definition.type === 'video'"
                        :value="selectedVideo"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="videos"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingVideos"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextVideos"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindVideos"
                        @input="selectedVideo = $event; attributes[definition.name] = $event.value"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>

                    <!-- csi_index_entry -->
                    <!--  -->
                    <!--  -->

                    <b-alert
                        v-else
                        show
                        variant="danger"
                    >
                        Unknown type: {{ definition.type }}
                    </b-alert>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button
                    size="sm"
                    variant="primary"
                    class="px-5"
                    v-if="enableContinueLater"
                    @click="onSave('continue')"
                >
                    Save And Continue Later
                </b-button>
                <b-button
                    size="sm"
                    variant="primary"
                    class="px-5 ml-2"
                    @click="onSave('save')"
                >
                    Save
                </b-button>
                <b-button
                    v-if="enableDelete"
                    size="sm"
                    variant="danger"
                    class="px-5"
                    @click="$emit('delete')"
                >
                    Delete
                </b-button>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import moment from "moment-timezone"
import { DirectUpload } from "@rails/activestorage"
import countryRegionData from "country-region-data/data"
import { blobImage } from "api/blob-image"
import { getCsiIndexEntries, getCsiIndexEntry } from "api/admin/csi-index-entries"
import { getFirms, getFirm } from "api/admin/firms"
import { getManufacturers, getManufacturer } from "api/admin/manufacturers"
import { getVideos, getVideo } from "api/admin/videos"
import { deepCopy } from "mixins/deep-copy"
import { mapGetters } from "vuex"
import Multiselect from "vue-multiselect"
import { vMaska } from "maska"

export default {
    components: {
        Multiselect
    },
    directives: { maska: vMaska },
    props: {
        definitions: { type: Array, required: true },
        record: { type: Object, default: null },
        errors: { type: Object, default: null },
        title: { type: String, default: "" },
        enableTitle: { type: Boolean, default: false },
        enableDelete: { type: Boolean, default: false },
        enableContinueLater: { type: Boolean, default: false }
    },

    data () {
        const baseURL = document.querySelector("meta[name=\"base-url\"]").content
        const directUploadPath = document.querySelector("meta[name=\"direct-upload-path\"]").content
        const directUploadUrl = directUploadPath.startsWith("/")
            ? baseURL + directUploadPath.slice(1)
            : baseURL + directUploadPath

        const attributes = this.definitions.reduce((hash, info) => {
            hash[info.name] = typeof info.default === "function" ? info.default() : info.default
            if (info.type === "image") {
                hash[`${ info.name }_path`] = null
                hash[`${ info.name }_picker`] = null
            }
            if (info.type === "duration") {
                hash[info.name] = "00:00:00"
            }
            return hash
        }, {})
        const attributeErrors = this.definitions.reduce((hash, info) => {
            hash[info.name] = ""
            return hash
        }, {})
        const attributeStates = this.definitions.reduce((hash, info) => {
            hash[info.name] = null
            return hash
        }, {})

        return {
            attributes,
            attributeErrors,
            attributeStates,
            directUploadUrl,
            internalImageId: 0,
            csiIndexEntries: [],
            isLoadingCsiIndexEntries: false,
            selectedCsiIndexEntry: null,
            primaryTypeCodeCsiIndexEntries: [],
            isLoadingPrimaryTypeCodeCsiIndexEntries: false,
            selectedPrimaryTypeCodeCsiIndexEntry: null,
            secondaryTypeCodeCsiIndexEntries: [],
            isLoadingSecondaryTypeCodeCsiIndexEntries: false,
            selectedSecondaryTypeCodeCsiIndexEntry: null,
            tertiaryTypeCodeCsiIndexEntries: [],
            isLoadingTertiaryTypeCodeCsiIndexEntries: false,
            selectedTertiaryTypeCodeCsiIndexEntry: null,
            firmProfiles: [],
            isLoadingFirmProfiles: false,
            selectedFirmProfile: null,
            manufacturerProfiles: [],
            isLoadingManufacturerProfiles: false,
            selectedManufacturerProfile: null,
            videos: [],
            isLoadingVideos: false,
            selectedVideo: null,
            showPassword: false,
            showPasswordConfirmation: false,
            moment,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        countries () {
            return countryRegionData.map(country => {
                return {
                    value: country.countryShortCode,
                    text: country.countryName
                }
            })
        },

        states () {
            let prohibited_states = ["American Samoa","Micronesia","Guam","Marshall Islands","Palau","Puerto Rico","Virgin Islands","Armed Forces Americas","Armed Forces Europe","Armed Forces Pacific","Armed Forces Europe, Canada, Africa and Middle East"]
            const country = this.attributes.country
            if (country) {
                const entry = countryRegionData.find(
                    entry => entry.countryShortCode === country)
                if (entry) {
                    return entry.regions.filter(f => !prohibited_states.includes(f.name) ).map(region => {
                        return { value: region.shortCode, text: region.name }
                    })
                }
            }
            return []
        },
    },

    watch: {
        "record": "applyRecordToAttributes",
        "errors": "updateErrors"
    },

    mounted () {
        console.log("Checking definitions with type csi_index_entry")
        this.loadCsiIndexEntry()
        this.loadPrimaryTypeCsiIndexEntry()
        this.loadSecondaryTypeCsiIndexEntry()
        this.loadTertiaryTypeCsiIndexEntry()
        this.loadFirmProfile()
        this.loadManufacturerProfile()
        this.loadVideo()
    },

    methods: {
        capitalize (s) {
            return s
                .replace(/(_at|_on|_id)$/, "")
                .replace(/_+/g, " ")
                .replace(/\b\w/g, l => l.toUpperCase())
        },

        updateErrors () {
            if (this.errors) {
                Object.keys(this.errors).forEach(attribute => {
                    const error = this.errors[attribute]
                    this.attributeErrors[attribute] = Array.isArray(error) ? error[0] : error
                })
            }
        },

        duration (value) {
            const hours = ~~(value / 3600)
            const seconds = value % 60
            if (hours === 0) {
                const minutes = ~~(value / 60)
                return `${hours<10 ? "0" + hours : hours}:${minutes<10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            } else {
                const minutes = ~~((value - hours * 3600) / 60)
                return `${hours<10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            }
        },

        applyRecordToAttributes () {
            if (this.record) {
                console.log("Applying values from record to local attributes")
                this.definitions.forEach(definition => {
                    const name = definition.name
                    let value = deepCopy(this.record[name])
                    this.attributes[name] = value
                    this.attributeStates[name] = null
                    this.attributeErrors[name] = ""
                    if (definition.type === "image") {
                        this.attributes[`${ name }_path`] = this.record[`${ name }_path`]
                    }
                    if(definition.type === 'duration'){
                       this.attributes[name] = this.duration(this.attributes[name])
                    }
                })
                this.loadCsiIndexEntry()
                this.loadPrimaryTypeCsiIndexEntry()
                this.loadSecondaryTypeCsiIndexEntry()
                this.loadTertiaryTypeCsiIndexEntry()
                this.loadFirmProfile()
                this.loadManufacturerProfile()
                this.loadVideo()
            }
        },

        onUpload (name, fileOrFiles) {
            if (Array.isArray(fileOrFiles)) {
                fileOrFiles.forEach(file => this.uploadAttachment(name, file))
            } else {
                this.uploadAttachment(name, fileOrFiles)
            }
        },

        onReset (name) {
            console.log(`Resetting ${ name }`, this.$refs[`${ name }_input`])
            // this.$refs[`${ name }_input`].reset()
            this.attributes[name] = null
            this.attributes[`${ name }_path`] = null
            this.attributes[`${ name }_picker`] = null
        },

        uploadAttachment (name, file) {
            if (file === null) return
            const upload = new DirectUpload(file, this.directUploadUrl)
            upload.create((error, blob) => {
                if (error) {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                } else {
                    blobImage(blob.signed_id)
                        .then(path => {
                            this.attributes[name] = blob.signed_id
                            this.attributes[`${ name }_path`] = path
                            this.attributes[`${ name }_picker`] = null
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to upload",
                                variant: "danger",
                                autoHideDelay: 5000
                            })                    
                            this.onReset(name)
                        })
                }
            })
        },

        onSave (saveStatus) {
            let record = {}
            if (this.record) {
                record.id = this.record.id
            }
            this.definitions.forEach(definition => {
                if (["boolean", "multiline", "image", "attachment", "email", "number",
                    "text", "number", "url", "tel", "country", "date", "datetime",
                    "state", "options", "csi_index_entry", "primary_csi_index_entry_code",
                    "secondary_csi_index_entry_code", "tertiary_csi_index_entry_code", "firm_profile",
                    "manufacturer_profile", "video", "color"].includes(definition.type))
                {
                    const name = definition.name
                    // if ((!this.record && this.attributes[name] !== definition.default) ||
                    if ((!this.record) ||
                        (this.record && this.record[name] !== this.attributes[name])) {
                        record[name] = this.attributes[name]
                    } else {
                        record[name] = this.record[name]
                    }
                } else if (definition.type === "password") {
                    if (this.attributes[definition.name] && !definition.name.endsWith("_confirmation")) {
                        if (this.attributes[definition.name] === this.attributes[definition.name + "_confirmation"]) {
                            record[definition.name] = this.attributes[definition.name]
                            record[definition.name + "_confirmation"] = this.attributes[definition.name + "_confirmation"]
                        } else {
                            this.$bvToast.toast("Password doesn't match confirmation", {
                                title: "Unable to save record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        }
                    }
                } else if (definition.type === "duration") {
                    if(this.attributes[definition.name].split(':').length === 3){
                        var hours = parseInt(this.attributes[definition.name].split(':')[0]) * 3600
                        var minutes = parseInt(this.attributes[definition.name].split(':')[1]) * 60
                        var seconds = parseInt(this.attributes[definition.name].split(':')[2])
                        record[definition.name] = hours + minutes + seconds
                    }else if (this.attributes[definition.name].split(':').length === 2){
                        var minutes = parseInt(this.attributes[definition.name].split(':')[0]) * 60
                        var seconds = parseInt(this.attributes[definition.name].split(':')[1])
                        record[definition.name] = minutes + seconds
                    }
                } else {
                    console.error(`Unable to copy attribute of type '${ definition.type }'`)
                }

            })

            console.log("Record to save:")
            console.dir(record)

            this.$emit("save", record, saveStatus)
        },

        limitTextCsiIndexEntries (count) {
            return `and ${ count } other entries`
        },

        limitTextPrimaryTypeCodeCsiIndexEntries (count) {
            return `and ${ count } other entries`
        },

        limitTextSecondaryTypeCodeCsiIndexEntries (count) {
            return `and ${ count } other entries`
        },

        limitTextTertiaryTypeCodeCsiIndexEntries (count) {
            return `and ${ count } other entries`
        },

        asyncFindCsiIndexEntries (query) {
            this.isLoadingCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { query })
                .then(result => {
                    const options = result.map(entry => {
                        return { value: entry.id, text: `${entry.code} ${entry.title}` }
                    })
                    this.csiIndexEntries.splice(0, this.csiIndexEntries.length, ...options)
                    this.isLoadingCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingCsiIndexEntries = false
                })
        },

        asyncFindPrimaryTypeCodeCsiIndexEntries (query) {
            this.isLoadingPrimaryTypeCodeCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { type: "primary_type", query })
                .then(result => {
                    const options = result.map(entry => {
                        return { value: entry.code, text: `${entry.code} ${entry.title}` }
                    })
                    this.primaryTypeCodeCsiIndexEntries.splice(0, this.primaryTypeCodeCsiIndexEntries.length, ...options)
                    this.isLoadingPrimaryTypeCodeCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingPrimaryTypeCodeCsiIndexEntries = false
                })
        },

        asyncFindSecondaryTypeCodeCsiIndexEntries (query) {
            this.isLoadingSecondaryTypeCodeCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { type: "secondary_type", query })
                .then(result => {
                    const options = result.map(entry => {
                        return { value: entry.code, text: `${entry.code} ${entry.title}` }
                    })
                    this.secondaryTypeCodeCsiIndexEntries.splice(0, this.secondaryTypeCodeCsiIndexEntries.length, ...options)
                    this.isLoadingSecondaryTypeCodeCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingSecondaryTypeCodeCsiIndexEntries = false
                })
        },

        asyncFindTertiaryTypeCsiIndexEntries (query) {
            this.isLoadingTertiaryTypeCodeCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { type: "tertiary_type", query })
                .then(result => {
                    const options = result.map(entry => {
                        return { value: entry.code, text: `${entry.code} ${entry.title}` }
                    })
                    this.tertiaryTypeCodeCsiIndexEntries.splice(0, this.tertiaryTypeCodeCsiIndexEntries.length, ...options)
                    this.isLoadingTertiaryTypeCodeCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingTertiaryTypeCodeCsiIndexEntries = false
                })
        },

        loadCsiIndexEntry () {
            const definition = this.definitions.find(d => d.type === "csi_index_entry")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getCsiIndexEntry(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `${ result.code } ${ result.title }`
                            }
                            this.csiIndexEntries.splice(0, this.csiIndexEntries.length, entry)
                            this.selectedCsiIndexEntry = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        loadPrimaryTypeCsiIndexEntry () {
            const definition = this.definitions.find(d => d.type === "primary_csi_index_entry_code")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getCsiIndexEntry(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `${ result.code } ${ result.title }`
                            }
                            this.primaryTypeCodeCsiIndexEntries.splice(0, this.primaryTypeCodeCsiIndexEntries.length, entry)
                            this.selectedPrimaryTypeCodeCsiIndexEntry = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        loadSecondaryTypeCsiIndexEntry () {
            const definition = this.definitions.find(d => d.type === "secondary_csi_index_entry_code")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getCsiIndexEntry(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `${ result.code } ${ result.title }`
                            }
                            this.secondaryTypeCodeCsiIndexEntries.splice(0, this.secondaryTypeCodeCsiIndexEntries.length, entry)
                            this.selectedSecondaryTypeCodeCsiIndexEntry = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        loadTertiaryTypeCsiIndexEntry () {
            const definition = this.definitions.find(d => d.type === "tertiary_csi_index_entry_code")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getCsiIndexEntry(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `${ result.code } ${ result.title }`
                            }
                            this.tertiaryTypeCodeCsiIndexEntries.splice(0, this.tertiaryTypeCodeCsiIndexEntries.length, entry)
                            this.selectedTertiaryTypeCodeCsiIndexEntry = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        limitTextFirmProfiles (count) {
            return `and ${ count } other firms`
        },

        asyncFindFirmProfiles (query) {
            this.isLoadingFirmProfiles = true
            getFirms(this.adminToken, { query })
                .then(result => {
                    const options = result.data.map(entry => {
                        return { value: entry.id, text: `#${entry.id} ${entry.company_name} - ${this.capitalize(entry.profile_state)}` }
                    })
                    this.firmProfiles.splice(0, this.firmProfiles.length, ...options)
                    this.isLoadingFirmProfiles = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingFirmProfiles = false
                })
        },

        loadFirmProfile () {
            const definition = this.definitions.find(d => d.type === "firm_profile")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getFirm(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `#${result.id} ${result.company_name}`
                            }
                            this.firmProfiles.splice(0, this.firmProfiles.length, entry)
                            this.selectedFirmProfile = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        limitTextManufacturerProfiles (count) {
            return `and ${ count } other manufacturers`
        },

        asyncFindManufacturerProfiles (query) {
            this.isLoadingManufacturerProfiles = true
            getManufacturers(this.adminToken, { query })
                .then(result => {
                    const options = result.data.map(entry => {
                        return { value: entry.id, text: `#${entry.id} ${entry.company_name}` }
                    })
                    this.manufacturerProfiles.splice(0, this.manufacturerProfiles.length, ...options)
                    this.isLoadingManufacturerProfiles = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingManufacturerProfiles = false
                })
        },

        loadManufacturerProfile () {
            const definition = this.definitions.find(d => d.type === "manufacturer_profile")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getManufacturer(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `#${result.id} ${result.company_name}`
                            }
                            this.manufacturerProfiles.splice(0, this.manufacturerProfiles.length, entry)
                            this.selectedManufacturerProfile = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        limitTextVideos (count) {
            return `and ${ count } other videos`
        },

        asyncFindVideos (query) {
            this.isLoadingVideos = true
            getVideos(this.adminToken, { query })
                .then(result => {
                    const options = result.data.map(entry => {
                        return { value: entry.id, text: `#${entry.id} ${entry.title}` }
                    })
                    this.videos.splice(0, this.videos.length, ...options)
                    this.isLoadingVideos = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingVideos = false
                })
        },

        loadVideo () {
            const definition = this.definitions.find(d => d.type === "video")
            if (definition) {
                const id = this.attributes[definition.name]
                if (id) {
                    getVideo(this.adminToken, { id })
                        .then(result => {
                            const entry = {
                                value: result.id,
                                text: `#${result.id} ${result.title}`
                            }
                            this.videos.splice(0, this.videos.length, entry)
                            this.selectedVideo = entry
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to load record",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                        })
                }
            }
        },

        togglePasswordVisibility (type) {
           if (type == 'password') {
              this.showPassword = !this.showPassword;
           } else {
              this.showPasswordConfirmation = !this.showPasswordConfirmation;
           }
            
        }
    },
}
</script>
