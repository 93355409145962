import { getSpecializations, getServices, getProfileTypes } from "api/firms"

export default {
    namespaced: true,

    state: {
        loadingSpecializationsPromise: null,
        specializations: [],
        loadingServicesPromise: null,
        services: [],
        loadingProfileTypesPromise: null,
        profile_types: [],
    },

    getters: {
        specializations: state => state.specializations,
        services: (state) => state.services,
        profile_types: (state) => state.profile_types,
    },

    mutations: {
        setLoadingSpecializationsPromise (state, promise) {
            state.loadingSpecializationsPromise = promise
        },

        setSpecializations (state, specializations) {
            state.loadingSpecializationsPromise = null
            state.specializations.splice(0, state.specializations.length, ...specializations)
        },

        setLoadingServicesPromise (state, promise) {
            state.loadingServicesPromise = promise
        },

        setServices (state, services) {
            state.loadingServicesPromise = null
            state.services.splice(0, state.services.length, ...services)
        },

        setLoadingProfileTypesPromise (state, promise) {
            state.loadingProfileTypesPromise = promise
        },

        setProfileTypes (state, profile_types) {
            state.loadingProfileTypesPromise = null
            state.profile_types.splice(0, state.profile_types.length, ...profile_types)
        }
    },

    actions: {
        loadSpecializations ({ state, commit }, { force = false } = {}) {
            if (!force) {
                if (state.loadingSpecializationsPromise) {
                    return state.loadingSpecializationsPromise
                }
                if (state.specializations.length !== 0) {
                    return Promise.resolve(state.specializations)
                }
            }
            const promise = getSpecializations()
                .then(specializations => {
                    commit("setSpecializations", specializations)
                })
                .catch(error => {
                    commit("setLoadingSpecializationsPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setLoadingSpecializationsPromise", promise)
            return promise
        },

        loadServices ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.loadingServicesPromise) {
                    return state.loadingServicesPromise
                }
                if (state.services.length !== 0) {
                    return Promise.resolve(state.services)
                }
            }
            const promise = getServices()
                .then(services => {
                    commit("setServices", services)
                })
                .catch(error => {
                    commit("setLoadingServicesPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setLoadingServicesPromise", promise)
            return promise
        },

        loadProfileTypes ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.loadingProfileTypesPromise) {
                    return state.loadingProfileTypesPromise
                }
                if (state.profile_types.length !== 0) {
                    return Promise.resolve(state.profile_types)
                }
            }
            const promise = getProfileTypes({})
                .then(profile_types => {
                    commit("setProfileTypes", profile_types)
                })
                .catch(error => {
                    commit("setLoadingProfileTypesPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setLoadingProfileTypesPromise", promise)
            return promise
        },
    }    
}
