import { getRequest } from "./base"

export const suggestFirms = (query) => {
    return getRequest("/firms/suggest", { query })
}

export const getFirmFilters = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/firms/filters", parameters)
}

export const getFirmKeywords = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/firms/keywords", parameters)
}

export const getFirms = (options = {}) => {
    return getRequest("/firms", options)
}

export const getFirm = ({ id }) => {
    return getRequest(`/firms/${id}`)
}

export const getFirmDirectory = (directoryType, options = {}) => {
    const parameters = { ...options }
    return getRequest(`/firms/directory/${ directoryType }`, parameters)
}

export const getSpecializations = () => {
    return getRequest("/specializations", {})
}

export const getServices = () => {
    return getRequest("/services", {})
}

export const getProfileTypes = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/firms/profile_types", parameters)
}
