<template>
    <footer class="footer pt-4 my-md-5 pt-md-5 border-top">
        <div class="container">
            <div class="row">
                <div class="col-6 col-md">
                    <h5>Products</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="footer-link" href="#">All Products</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>    
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .footer {
        h5 {
            font-size: 22px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: 0.14px;
            color: #000000;            
        }

        .footer-link {
            font-family: AvenirNext-Regular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000000;
        }
    }
</style>
