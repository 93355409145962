import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFeaturedFirms = (token, { page = 1, per_page = 25, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page, per_page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'updated_at'
        params["direction"] = "desc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/featured_firms", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFeaturedFirm = (token, { id }) => {
    return getRequest(`/admin/featured_firms/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFeaturedFirm = (token, featuredFirm) => {
    if (featuredFirm.id) {
        let { id, ...featuredFirmWithoutId } = featuredFirm
        return patchRequest(`/admin/featured_firms/${ id }`, {
            featured_firm: featuredFirmWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/featured_firms", {
            featured_firm: featuredFirm
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFeaturedFirm = (token, featuredFirm) => {
    return deleteRequest(`/admin/featured_firms/${ featuredFirm.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const bulkActionFeaturedFirm = (token, b_action, requestIds) => {
    if (requestIds) {
        return patchRequest("/admin/featured_firms/bulk_action", {
            item_ids: requestIds,
            b_action: b_action
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}
