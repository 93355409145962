<template>
    <main>
        <page-header />

        <section class="container-fluid">
            <router-view />
        </section>

        <page-footer v-if="false" />
    </main>
</template>

<script>
import PageHeader from "components/layout/admin/header"
import PageFooter from "components/layout/admin/footer"

export default {
    components: { PageHeader, PageFooter }
}
</script>
