import { getRequest, patchRequest, postRequest } from "./base"

// Professional applications

export const getFirms = () => {
    return getRequest("/applications/firms")
}

export const getFirm = (id) => {
    return getRequest(`/applications/firms/${ id }`)
}

export const createFirm = (attributes) => {
    return postRequest("/applications/firms", { firm_profile: attributes })
}

export const saveFirm = firmProfile => {
    const id = firmProfile.id
    if (!id) {
        console.log("bad profile:", firmProfile)
    }
    return patchRequest(`/applications/firms/${ id }`, {
        firm_profile: firmProfile
    })
}

export const upgradeFirm = (firmProfile, subscriptionAttributes) => {
    const id = firmProfile.id
    if (!id) {
        console.log("bad profile:", firmProfile)
    }
    return patchRequest(`/applications/firms/${ id }/upgrade`, {
        firm_profile: {
            firm_subscriptions_attributes: subscriptionAttributes
        }
    })
}

// Manufacturer applications

export const searchManufacturers = (company_name) => {
    return getRequest(`/applications/manufacturers/search?company_name=${company_name}`)
}

export const getManufacturers = () => {
    return getRequest("/applications/manufacturers")
}

export const getManufacturer = (id) => {
    return getRequest(`/applications/manufacturers/${ id }`)
}

export const createManufacturer = (attributes) => {
    return postRequest("/applications/manufacturers", { manufacturer_profile: attributes })
}

export const saveManufacturer = manufacturerProfile => {
    const id = manufacturerProfile.id
    if (!id) {
        console.log("bad profile:", manufacturerProfile)
    }
    return patchRequest(`/applications/manufacturers/${ id }`, {
        manufacturer_profile: manufacturerProfile
    })
}

export const upgradeManufacturer = (manufacturerProfile, subscriptionAttributes) => {
    const id = manufacturerProfile.id
    if (!id) {
        console.log("bad profile:", manufacturerProfile)
    }
    return patchRequest(`/applications/manufacturers/${ id }/upgrade`, {
        manufacturer_profile: {
            manufacturer_subscriptions_attributes: subscriptionAttributes
        }
    })
}
