import { getRequest } from "./base"

export const escapeCsiCode = (code) =>  {
    const codeVal = code && code.replace(/[^0-9]/g, "-")
    if (codeVal.split("-").length > 3) {
        const codeNew = codeVal.split("-")
        return `${codeNew[0]}-${codeNew[1]}-${codeNew[2]}.${codeNew[3]}`
    } else {
        return codeVal
    }
}
export const unescapeCsiCode = (code) =>  {
    const codeVal = code && code.replace(/[^0-9]/g, " ")
    if (codeVal.split(" ").length > 3) {
        const codeNew = codeVal.split(" ")
        return `${codeNew[0]} ${codeNew[1]} ${codeNew[2]}.${codeNew[3]}`
    } else {
        return codeVal
    }
}

export const getCsiIndexEntry = ({ code }) => {
    const escapedCode = code ? escapeCsiCode(code) : "root"
    return getRequest(`/csi_entries/${ escapedCode }`)
}

export const getCsiIndexEntrySlug = ({ slug }) => {
    return getRequest(`/csi_entries/${ slug }`)
}

export const getCsiIndexEntries = (
    {
        page = 1,
        sortBy = "",
        sortDesc = false,
        query
    } = {}
) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/csi_entries/search", params)
}

const csiIndexEntries = ({ parentCode = null } = {}) => {
    return getRequest("/csi_entries", { parent: parentCode })
}

const csiDivision = ({ code = null } = {}) => {
    return getRequest("/csi_entries/division", { code })
}

const divisionNestedSubDivisions = ({ division = null, nested_children = null, exceptIds = [] } = {}) => {
    let params = { division, nested_children }
    if(!!exceptIds.length){
        params["except_ids"] = exceptIds
    }
    return getRequest("/csi_entries/division_nested_sub_divisions", params)
}

const csiSubDivisions = ({ query }) => {
    return getRequest("/csi_entries/sub_divisions", { query })
}

const csiProductTypes = ({ parentIds }) => {
    console.log("!!!parentIds", parentIds)
    return getRequest("/csi_entries/product_types", { parent_ids: parentIds })
}

const csiProductTypesDirectoryByName = (letter) => {
    if (letter) {
        return getRequest("/manufacturers_directory/product_types", { letter })
    }
    return getRequest("/manufacturers_directory/product_types")
}

export {
    csiIndexEntries,
    csiDivision,
    csiSubDivisions,
    csiProductTypes,
    csiProductTypesDirectoryByName,
    divisionNestedSubDivisions
}
