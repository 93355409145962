import { ApiError, postRequest, patchRequest, getRequest } from "./base"

export const signin = ({ email, password }) => {
    return postRequest("/signin", { email, password })
}

export const refreshUser = ({ id }) => {
    return getRequest(`/users/refresh_user?user_id=${id}`)
}

export const sendResetPassword = ({ email }) => {
    return patchRequest("/users/send_reset_password", { email })
}

export const resetPassword = ({ user_id, passwordResetToken, password, passwordConfirmation }) => {
    return patchRequest("/users/reset_password", { user: { id: user_id, password, password_confirmation: passwordConfirmation }, password_reset_token: passwordResetToken })
}

export const changePassword = ({ email, currentPassword, password, passwordConfirmation }) => {
    return patchRequest("/users/change_password", { user: { password, password_confirmation: passwordConfirmation }, current_password: currentPassword, email })
}

export const signup = user => {
    return postRequest("/users", { user })
}

export const adminSignin = ({ email, password }) => {
    return postRequest("/admin/signin", { email, password })
}

export const adminSendResetPassword = ({ email }) => {
    return patchRequest("/admin/sessions/send_reset_password", { email })
}

export const adminResetPassword = ({ admin_id, passwordResetToken, password, passwordConfirmation }) => {
    return patchRequest("/admin/sessions/reset_password", { admin: { id: admin_id, password, password_confirmation: passwordConfirmation }, password_reset_token: passwordResetToken })
}

export const adminChangePassword = ({ email, currentPassword, password, passwordConfirmation }) => {
    return postRequest("/admin/sessions/change_password", { admin: { password, password_confirmation: passwordConfirmation }, current_password: currentPassword, email })
}

export const upgradeUser = () => {
    return patchRequest("/users/upgrade", {})
}

export const upgradeUserTo = (priceId) => {
    return patchRequest("/users/upgrade_to", { price_id: priceId })
}

export const changePaymentMethod = (body) => {
    const user = {...body}
    return patchRequest("/users/change_payment_method", { user })
}

export {
    ApiError,
}
