<template>
    <div @mouseover="onOver" @mouseleave="onLeave">
        <b-nav-item-dropdown
            ref="dropdown"
            :text="text"
            no-caret
            :menu-class="{'scrollable' : scrollable }"
            @toggle="redirectTo(pageToGo)"
        >
            <slot />
        </b-nav-item-dropdown>
    </div>
</template>

<script>
export default {
    name: "BDropdownHover",
    props: {
        text: { required: true, type: String },
        pageToGo: { type: String},
        scrollable: { type: Boolean, default: false}
    },
    methods: {
        onOver() {
            this.$refs.dropdown.visible = true
        },
        onLeave() {
            this.$refs.dropdown.visible = false
        },
        redirectTo(pageToGo) {
            this.$router.push(pageToGo)
        }
    }
}
</script>

<style lang="scss">
  .dropdown-menu.scrollable{
    height: auto;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
