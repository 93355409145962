import Vue from "vue"
import App from "../app.vue"
import axios from "axios"
import store from "store"
import router from "router"
import { BootstrapVue, BootstrapVueIcons, LayoutPlugin, ModalPlugin } from "bootstrap-vue"
import PortalVue from "portal-vue"
import "filters"
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import "vue-multiselect/dist/vue-multiselect.min.css"
import "assets/stylesheets/fonts.scss"
import "assets/stylesheets/bootstrap.scss"
import "assets/stylesheets/common.scss"
import infiniteScroll from 'vue-infinite-scroll'
import VModal from 'vue-js-modal'
// import { VueEditor, Quill } from "vue2-editor";
// import { ImageDrop } from 'quill-image-drop-module'
// import ImageResize from 'quill-image-resize';
// Quill.register('modules/imageDrop', ImageDrop)
// Quill.register('modules/imageResize', ImageResize)

// Vue.use(VueEditor)
Vue.use(infiniteScroll)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(PortalVue)
Vue.use(VModal)


// library.add(faUserSecret)
// Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.config.productionTip = false

// Initialize Axios
axios.defaults.baseURL = document.querySelector("meta[name=\"base-url\"]").content + "api/v1/"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.patch["Content-Type"] = "application/json"
axios.defaults.validateStatus = () => true // always process internally to access error message

document.addEventListener("DOMContentLoaded", () => {
    const app = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount()
    document.body.appendChild(app.$el)
})
