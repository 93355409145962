<template>
    <div class="content">
        <h1 v-if="currentTab==0">Events</h1>
        <!-- <h1 v-else-if="currentTab==1">Event Stories</h1> -->
        <h1 v-else>Event Issues</h1>
        <b-tabs
            v-model="currentTab"
            content-class="mt-3"
        >
            <b-tab title="Events" active />
            <!-- <b-tab title="Event Stories" /> -->
            <b-tab title="Event Issues" />
        </b-tabs>

        <b-row>
            <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                    v-if="currentTab == 0"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-event' }"
                        >
                            New Event
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
<!--                 <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                    v-else-if="currentTab == 1"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-event-story' }"
                        >
                            New Event Story
                        </b-button>
                    </b-button-group>
                </b-button-toolbar> -->
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                    v-else
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-event-issue' }"
                        >
                            New Event Issue
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-events' }"
                    :link-gen="linkGen"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            table-class=""
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            responsive
            @row-clicked="onClickRow"
            v-if="currentTab==0"
        >
            <template #cell(active)="row">
                {{ row.item.active ? 'Yes' : 'No' }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table>

<!--         <b-table
            id="index-table"
            :fields="eventStoryFields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            table-class=""
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            responsive
            @row-clicked="onClickEventStoryRow"
            v-else-if="currentTab == 1"
        >
            <template #cell(active)="row">
                {{ row.item.active ? 'Yes' : 'No' }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table> -->

        <b-table
            id="index-table"
            :fields="eventIssueFields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            table-class=""
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            responsive
            @row-clicked="onClickEventIssueRow"
            v-else
        >
            <template #cell(active)="row">
                {{ row.item.active ? 'Yes' : 'No' }}
            </template>

            <template #cell(issued_on)="row">
                {{ row.item.issued_on | date }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table>

        <div class="d-flex justify-content-end">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="lastPage"
                :limit="7"
                :to="{ name: 'admin-events' }"
                :link-gen="linkGen"
                :disabled="paginationDisable"
                @page-click="onPageClick"
            />
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getEvents } from "api/admin/events"
import { getEventStories } from "api/admin/event-stories"
import { getEventIssues } from "api/admin/event-issues"
import { mapActions, mapGetters } from "vuex"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            paginationDisable: false,
            items: [],
            currentTab: 0,
            totalItems: 0,
            itemsPerPage: 25,
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "name", label: "Name", sortable: true, class: "text-nowrap column-left" },
                { key: "active", label: "Active", sortable: true, class: "text-nowrap column-left" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
            eventStoryFields: [
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "title", label: "Title", sortable: true, class: "text-nowrap column-left" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
            eventIssueFields: [
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "issued_on", label: "Issued on", sortable: true, class: "text-nowrap column-left" },
                { key: "active", label: "Active", sortable: true, class: "text-nowrap column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadEvents",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },

        currentTab () {
            this.loadRecords()
        },
    },

    mounted () {
        this.loadEvents()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        onSearch () {
            this.loadRecords();
        },
        
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },
        loadRecords () {
            if(this.currentTab==0){
                this.loadEvents();
            // }else if(this.currentTab==1){
            //     this.loadEventStories();
            }else{
                this.loadEventIssues();
            }
        },
        loadEvents () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            this.paginationDisable = true;
            getEvents(this.adminToken, {
                page: this.currentPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_count
                    this.lastPage = result.meta.total_pages
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        loadEventStories () {
            this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            getEventStories(this.adminToken, {
                page: this.page,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            })
                .then(result => {
                    this.page = result.meta.page
                    this.totalItems = result.meta.total_pages * result.meta.per_page
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        loadEventIssues () {
            this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            getEventIssues(this.adminToken, {
                page: this.page,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            })
                .then(result => {
                    this.page = result.meta.page
                    this.totalItems = result.meta.total_pages * result.meta.per_page
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-show-event",
                params: { id: item.id }
            })
        },

        onClickEventIssueRow (item) {
            this.$router.push({
                name: "admin-show-event-issue",
                params: { id: item.id }
            })
        },

        onClickEventStoryRow (item) {
            this.$router.push({
                name: "admin-show-event-story",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
</style>