<template>
    <b-row class="position-relative" style="height: 3rem;">
        <b-button id="pagetop" style="position: absolute; right: 0px; bottom: 0px; z-index: 100;" v-if="enableBackToTop" @click="toTop">
            Back To Top
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 28" fill="none"
               stroke="#ffffff"
               stroke-width="3" stroke-linecap="square" stroke-linejoin="arcs">
            <path d="M18 15l-6-6-6 6"/>
            </svg>
        </b-button>
    </b-row>
</template>

<script>
    import BackToTopMixin from "mixins/back_to_top.js"
    export default {
        props: {
            enableBackToTop: { type: Boolean, default: true}
        },
        mixins: [BackToTopMixin],
    }
</script>
