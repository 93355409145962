<template>
    <div class="content">
        <h1>User #{{ record.id }}</h1>

        <b-button-toolbar
            aria-label="Actions"
            key-nav
            class="mb-3"
        >
            <b-button-group class="mx-1">
                <b-button
                    :to="{ name: 'admin-edit-user', params: { id: $route.params.id } }"
                >
                    Edit User
                </b-button>
            </b-button-group>

            <b-button-group class="mx-1">
                <b-button
                    variant="danger"
                    @click="onClickDelete"
                >
                    Delete
                </b-button>
            </b-button-group>
        </b-button-toolbar>

        <show-columns
            :definitions="definitions"
            :record="record"
        />

      <!--   <div v-if="record.favorite_files.length > 0">
            <h3>Favorite Files</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>ID</b-th>
                        <b-th>Slug</b-th>
                        <b-th>Name</b-th>
                        <b-th>Time</b-th>
                        <b-th>Actions</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="file in record.favorite_files"
                        :key="file.id"
                    >
                        <b-td class="text-nowrap">{{ file.id }}</b-td>
                        <b-td class="text-nowrap">{{ file.slug }}</b-td>
                        <b-td class="text-nowrap">{{ file.name }}</b-td>
                        <b-td class="text-nowrap">{{ file.created_at | date }}</b-td>
                        <b-td class="text-nowrap">
                            <b-button
                                variant="danger"
                                size="sm"
                                @click.prevent="onClickFavoriteFileDelete(file)"
                            >
                                Delete
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div> -->

        <div >
            <h3>Managed Firms</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>ID</b-th>
                        <b-th>Created</b-th>
                        <b-th>Name</b-th>
                        <b-th>URL</b-th>
                        <b-th>City</b-th>
                        <b-th>ST/PR</b-th>
<!--                         <b-th>ID</b-th>
                        <b-th>Company Name</b-th>
                        <b-th>Tier</b-th>
                        <b-th>Profile State</b-th>
                        <b-th>Country</b-th>
                        <b-th>State</b-th>
                        <b-th>City</b-th>
                        <b-th>Address</b-th> -->
                    </b-tr>
                </b-thead>
                <b-tbody>
<!--                     <b-tr
                        v-for="profile in record.firm_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-firm', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.tier }}</b-td>
                        <b-td class="text-nowrap">{{ profile.profile_state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.country }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.address }}</b-td>
                    </b-tr> -->
                    <b-tr
                        v-for="profile in record.firm_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">{{ profile.created_at | date }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-firm', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.site }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>

        <div >
            <h3>Managed Manufacturers</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>ID</b-th>
                        <b-th>Company Name</b-th>
                        <b-th>Status</b-th>
                        <b-th>Profile State</b-th>
                        <b-th>Country</b-th>
                        <b-th>State</b-th>
                        <b-th>City</b-th>
                        <b-th>Address</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="profile in record.manufacturer_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-manufacturer', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.status }}</b-td>
                        <b-td class="text-nowrap">{{ profile.profile_state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.country }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.address }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getUser, deleteUser, deleteFavoriteFile } from "api/admin/users"
import ShowColumns from "../shared/show-columns"

export default {
    components: { ShowColumns },

    data () {
        const definitions = [
            { name: "id", type: "number", default: null },
            { name: "email", type: "email", default: "" },
            { name: "confirmation_token", type: "text", default: "" },
            { name: "confirmed_at", type: "datetime", default: null },
            { name: "name", type: "text", default: "" },
            { name: "company_name", type: "text", default: "" },
            { name: "phone", type: "tel", default: "" },
            { name: "country", type: "country", default: "" },
            { name: "address", type: "text", default: "" },
            { name: "city", type: "text", default: "" },
            { name: "state", type: "state", default: "" },
            { name: "zip", type: "text", default: "" },
            { name: "description", type: "multiline", default: "" },
            // { name: "profile_type", type: "text", default: "" },
            // { name: "manufacturer_profile_id", type: "id", class: "manufacturer", default: null },
            // { name: "firm_profile_id", type: "id", class: "firm", default: null },
            { name: "created_at", type: "datetime", default: null },
            { name: "updated_at", type: "datetime", default: null },
        ]
        const record = definitions.reduce((hash, info) => {
            hash[info.name] = typeof info.default === "function" ? info.default() : info.default
            if (info.type === "image") {
                hash[`${ info.name }_path`] = null
            }
            return hash
        }, {})
        record.id = this.$route.params.id
        record.favorite_files = []
        record.firm_profiles = []
        record.manufacturer_profiles = []
        return {
            definitions,
            record,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadRecord",
    },

    mounted () {
        this.loadRecord()
    },

    methods: {
        loadRecord () {
            getUser(this.adminToken, { id: this.record.id })
                .then(result => {
                    result.admin_edits = undefined
                    Object.assign(this.record, result)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to load record",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickDelete () {
            const message = `Are you sure you want to delete “${ this.record.id }”? This record will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConfirmed => {
                    if (isConfirmed) {
                        deleteUser(this.adminToken, this.record)
                            .then(() => {
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-users" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickFavoriteFileDelete (favoriteFile) {
            const message = `Are you sure you want to delete favorite file “${ this.record.name }”? This record will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            }).then(isConfirmed => {
                if (isConfirmed) {
                    deleteFavoriteFile(
                        this.adminToken,
                        favoriteFile
                    ).then(() => {
                        console.log(`trying to find ${favoriteFile.id}`)
                        const index = this.record.favorite_files.
                            findIndex(ff => ff.id === favoriteFile.id)
                        console.log(`index is ${index}`)
                        if (index !== -1) {
                            this.record.favorite_files.splice(index, 1)
                            console.log("favorite_files is", this.record.favorite_files)
                        }
                    }).catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
                }
            })
        },
    }
}
</script>
