export const occupationOptions = [
    { value: "architect", text: "Architect" },
    { value: "architectural_illustrator", text: "Architectural Illustrator" },
    { value: "architectural_photographer", text: "Architectural Photographer" },
    { value: "art_consultant", text: "Art Consultant" },
    { value: "building", text: "Building" },
    { value: "building_developer", text: "Building Developer" },
    { value: "building_manager", text: "Building Manager" },
    { value: "building_tradesperson", text: "Building Tradesperson" },
    { value: "computer_professional", text: "Computer Professional" },
    { value: "consumer", text: "Consumer" },
    { value: "educator", text: "Educator" },
    { value: "engineer", text: "Engineer" },
    { value: "government_employee", text: "Government Employee" },
    { value: "interior_designer", text: "Interior Designer" },
    { value: "landscape_architect", text: "Landscape Architect" },
    { value: "lighting_designer", text: "Lighting Designer" },
    { value: "manufacturer", text: "Manufacturer" },
    { value: "media_professional", text: "Media Professional" },
    { value: "retailer_distributor", text: "Retailer Distributor" },
    { value: "specifier", text: "Specifier" },
    { value: "student", text: "Student" },
    { value: "urban_planner", text: "Urban Planner" },
    { value: "other", text: "Other" },
]
