import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getUsers = (token, { page = 1, sortBy = "", sortDesc = false, tab } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (tab) {
        params['tab'] = tab
    }
    return getRequest("/admin/users", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getUser = (token, { id }) => {
    return getRequest(`/admin/users/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveUser = (token, user) => {
    if (user.id) {
        let { id, ...userWithoutId } = user
        return patchRequest(`/admin/users/${ id }`, {
            user: userWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/users", {
            user: user
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteUser = (token, user) => {
    return deleteRequest(`/admin/users/${ user.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const deleteFavoriteFile = (token, favoriteFile) => {
    return deleteRequest(`/admin/favorite_files/${ favoriteFile.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

