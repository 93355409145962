<template>
    <div class="show-columns">
        <b-table-simple
            small
        >
            <b-tbody>
                <b-tr
                    v-for="definition of definitions"
                    :key="definition.name"
                >
                    <b-td>
                        <strong>{{ definition.label || capitalize(definition.name) }}</strong>
                    </b-td>

                    <b-td>
                        <template v-if="record[definition.name] === null && definition.type !== 'id'">
                            <span class="special">&lt;NULL&gt;</span>
                        </template>

                        <template v-else-if="definition.type === 'id'">
                            <span
                                v-if="record[`${ definition.name }_id`] === null"
                                class="special"
                            >
                                &lt;NULL&gt;
                            </span>
                            <router-link
                                v-else
                                :to="{ name: `admin-show-${ definition.class.replace(/_+/g, '-') }`, params: { id: record[`${ definition.name }_id`] } }"
                            >
                                {{ capitalize(definition.class).replace(/ +/g, '') }}
                                #{{ record[`${ definition.name }_id`] }}
                            </router-link>
                        </template>

                        <template v-else-if="definition.type === 'datetime'">
                            {{ record[definition.name] | date }}
                        </template>

                        <template v-else-if="definition.type === 'duration'">
                            {{ record[definition.name] | duration }}
                        </template>

                        <template v-else-if="definition.type === 'date'">
                            {{ record[definition.name] | date }}
                        </template>

                        <template v-else-if="definition.type === 'color'">
                            {{ record[definition.name] || "--" }}
                        </template>

                        <template v-else-if="definition.type === 'boolean'">
                            {{ record[definition.name] ? 'Yes' : 'No' }}
                        </template>

                        <!-- <template v-else-if="definition.type === 'service'">
                            {{ record[definition.name] | service }}
                        </template> -->

                        <template v-else-if="definition.type === 'entry_type'">
                            {{ record[definition.name] | entryType }}
                        </template>

                        <template v-else-if="definition.type === 'attachment'">
                            <iframe
                                class="html-preview"
                                title="Inline Frame Example"
                                width="400"
                                height="200"
                                :src="record[`${ definition.name }_path`]"
                            />
                        </template>

                        <template v-else-if="definition.type === 'image'">
                            <b-img
                                :src="record[`${ definition.name }_path`]"
                                fluid
                                alt="Responsive image"
                            />
                        </template>

                        <template v-else-if="definition.type === 'country'">
                            {{ record[definition.name] | country }}
                        </template>

                        <template v-else-if="definition.type === 'region'">
                            {{ record[definition.name] | region(record.country) }}
                        </template>

                        <template v-else-if="definition.type === 'service'">
                            <router-link
                                :to="{ name: 'admin-show-service', params: { id: record[definition.name].id } }"
                            >
                                {{  record[definition.name].name }}
                            </router-link>
                        </template>

                        <template v-else-if="(definition.type === 'text' || definition.type === 'multiline') && record[definition.name] === ''">
                            <span class="special">&lt;BLANK&gt;</span>
                        </template>

                        <template v-else-if="definition.type === 'specializations'">
                            {{ record.specializations.map(specialization => {
                                return specialization.parent && specialization.category_name
                                    ? `${ specialization.parent.category_name }: ${ specialization.name }`
                                    : specialization.name
                            }).join(", ") }}
                        </template>

                        <template v-else>
                            {{ record[definition.name] }}
                        </template>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <div
            v-if="!!record.admin_edits"
        >
            <h3>Admin Edits</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>Admin</b-th>
                        <b-th>Column</b-th>
                        <b-th>Old Value</b-th>
                        <b-th>New Value</b-th>
                        <b-th>Time</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="edit in record.admin_edits"
                        :key="edit.id"
                    >
                        <b-td class="text-nowrap">{{ edit.admin.username }}</b-td>
                        <b-td class="text-nowrap">{{ edit.column }}</b-td>
                        <b-td class="text-nowrap">{{ edit.old_value ? edit.old_value : "NULL" }}</b-td>
                        <b-td class="text-nowrap">{{ edit.new_value ? edit.new_value : "NULL" }}</b-td>
                        <b-td class="text-nowrap">{{ edit.created_at | date }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { entryTypeOptions } from "enums/entry-types"
// import { optionsToDictionary } from "mixins/collection"

export default {
    filters: {
        entryType (value) {
            const option = entryTypeOptions.find(option => option.value === value)
            return option ? option.text : option
        },
        duration (value) {
            const hours = ~~(value / 3600)
            const seconds = value % 60
            if (hours === 0) {
                const minutes = ~~(value / 60)
                return `${minutes<10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            } else {
                const minutes = ~~((value - hours * 3600) / 60)
                return `${hours<10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
            }
        }
    },

    props: {
        definitions: { type: Array, required: true },
        record: { type: Object, required: true },
    },

    data () {
        return {
        }
    },

    methods: {
        capitalize (s) {
            return s
                .replace(/(_at|_on)$/, "")
                .replace(/_+/g, " ")
                .replace(/\b\w/g, l => l.toUpperCase())
        },
    },
}
</script>

<style lang="scss" scoped>
.special {
    background-color: #ccc;
    font-weight: bold;
    font-size: 14px;
}
.html-preview {
    width: 100%;
    border: 0 none;    
}
</style>
