import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getHeroes = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/heroes", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getHero = (token, { id }) => {
    return getRequest(`/admin/heroes/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveHero = (token, hero) => {
    if (hero.id) {
        let { id, ...heroWithoutId } = hero
        return patchRequest(`/admin/heroes/${ id }`, {
            hero: heroWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/heroes", {
            hero: hero
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteHero = (token, hero) => {
    return deleteRequest(`/admin/heroes/${ hero.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
