import { getRequest, patchRequest, postRequest, deleteRequest } from "./../base"

export const getReports = (token, { page = 1, per_page = 100, sortBy = "", sortDesc = false, approvalState = undefined, query } = {}) => {
    let params = { page, per_page }
    if (approvalState) {
        params["approval_state"] = approvalState
    }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/reports", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getReport = (token, { id }) => {
    return getRequest(`/admin/reports/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveReport = (token, report) => {
    if (report.id) {
        let { id, ...reportWithoutId } = report
        return patchRequest(`/admin/reports/${ id }`, {
            report: reportWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/reports", {
            report: report
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteReport = (token, report) => {
    return deleteRequest(`/admin/reports/${ report.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
