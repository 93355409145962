<template>
    <div
        id="app"
        :class="layout"
    >
        <component :is="layoutComponent" />
    </div>
</template>

<script>
import AdminLayout from "components/layout/admin"
import AuthLayout from "components/layout/auth"
import MainLayout from "components/layout/main"

export default {
    components: {
        AdminLayout,
        AuthLayout,
        MainLayout
    },

    computed: {
        layout () {
            return this.$route.meta.layout || "main"
        },

        layoutComponent () {
            return `${ this.layout }-layout`
        },
    },
}
</script>

<style lang="scss" scoped>
.admin {
    //
}

.auth {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.main {
    //
}
</style>
