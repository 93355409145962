<template>
    <footer class="footer pt-3 my-md-4 pt-md-3 d-print-none px-md-2" style="border-top: 1px solid #a0a0a0 !important;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-md">
                    <h5>Products</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'products-directory-index' }"
                            >
                                All Products
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'products-directory-index' }"
                            >
                                CSI MasterFormat Index
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-all-types-page' }"
                            >
                                Product Types
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-company-name-page' }"
                            >
                                Manufacturer Names
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'product-company-name-page' , query: { green: 'yes' } }"
                            >
                                Green Products
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'showcase-your-products' }"
                            >
                                Showcase your Products
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Professionals</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'design-firms', query: { country: 'us' } }"
                            >
                                All Design Firms
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'design-firms', query: { country: 'us' } }"
                            >
                                Service Types
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'location' } }"
                            >
                                Location
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'specialization' } }"
                            >
                                Specialization
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-directory', params: { directoryType: 'company-name' } }"
                            >
                                Firm names
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'firm-application-confirmation' }"
                            >
                                Profile Your Firm
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Newsletters +</h5>
                    <ul class="list-unstyled text-small">
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'news' }"
                            >
                                Industry News
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'events' }"
                            >
                                Events&nbsp;+&nbsp;Expos
                            </b-link>
                        <!--                          <a class="footer-link" href="#">Events&nbsp;+&nbsp;Expos</a></li>-->
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'building-product-showcase' }"
                            >
                                Product Showcase
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'learning-center' }"
                            >
                                CEU Learning Center
                            </b-link>
                        </li>
                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'videos' }"
                            >
                                Videos
                            </b-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h5>Administration</h5>
                    <ul class="list-unstyled text-small">
                        <li><a class="footer-link" href="#">About DG</a></li>
                        <li><a class="footer-link" href="#">Contact Us</a></li>

                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-manufacturer' }"
                            >
                                Manufacturer Admin
                            </b-link>
                        </li>

                        <li>
                            <b-link
                                class="footer-link"
                                :to="{ name: 'settings-applications-firm' }"
                            >
                                Design Firm Admin
                            </b-link>
                        </li>

                        <li>
                            <a class="footer-link" href="#">Subscriber Services</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>    
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .footer {
        h5 {
            font-family: Helvetica, Sans-Serif;
            font-size: 22px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: 0.14px;
            color: #000000;            
        }

        .footer-link {
            font-family: 'Helvetica Neue Light';
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 1.5px;
            color: #000000;
        }
    }
</style>
