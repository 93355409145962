const quickLinks = {
    "products": "Products",
    "specifications": "Specifications",
    "cad": "CAD",
    "request_info": "Request Info / Cat.",
    "catalogs": "Catalogs",
    "leed_info": "LEED Info",
    "bim": "BIM",
    "request_samples": "Request Samples",
    "videos": "Videos",
    "ceu": "CEU",
    "revit": "Revit",
    "request_quote": "Request Quote",
}

export default quickLinks
