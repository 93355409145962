import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getCsiProductTypes = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/csi_product_types", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getCsiProductType = (token, { id }) => {
    return getRequest(`/admin/csi_product_types/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveCsiProductType = (token, csiProductType) => {
    if (csiProductType.id) {
        let { id, ...csiProductTypeWithoutId } = csiProductType
        return patchRequest(`/admin/csi_product_types/${ id }`, {
            csi_product_type: csiProductTypeWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/csi_product_types", {
            csi_product_type: csiProductType
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteCsiProductType = (token, csiProductType) => {
    return deleteRequest(`/admin/csi_product_types/${ csiProductType.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
