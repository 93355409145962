<template>
    <main class="main-margin">
        <page-header />

        <section class="container-fluid">
            <keep-alive>
                <router-view />
            </keep-alive>
        </section>

        <page-footer />

        <div class="copyright pt-2 my-md-3 d-print-none" style="border-top: 1px solid #a0a0a0 !important;">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md text-center">
                        Copyright ©
                        {{ Date() | datetime("YYYY") }}
                        Designguide, LLC. All rights reserved.
                        <router-link class="footer-link" :to="{ name: 'private-policy' }">Privacy.</router-link>
                        <router-link class="footer-link" :to="{ name: 'agreement' }">Terms of Use.</router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import PageHeader from "./header"
import PageFooter from "./footer"

export default {
    components: { PageHeader, PageFooter },

    computed: {
        ...mapGetters("utils", ["isMobile"])
    },

    mounted () {
        window.addEventListener("resize", this.onUpdateMobile)
        this.onUpdateMobile()
    },

    destroyed () {
        window.removeEventListener("resize", this.onUpdateMobile)
    },

    methods: {
        ...mapActions("utils", ["setMobile"]),

        onUpdateMobile () {
            const flag = window.innerWidth < 576
            if (this.isMobile !== flag) {
                this.setMobile(flag)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .copyright {
        font-size: 16px;
        font-weight: 500;
        color: #225a90;
    
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: -5px;
            margin-top: 10px;
        }
        .footer-link {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #225a90;
        }
    }
    .main-margin{
        margin: 0 0.7rem;
    }
</style>
