import {
    getFirms,
    getManufacturers
} from "api/applications"

export default {
    namespaced: true,

    state: {
        firmApplicationsPromise: null,
        firmApplications: [],
        manufacturerApplicationsPromise: null,
        manufacturerApplications: [],
    },

    getters: {
        firmApplications: (state) => state.firmApplications,
        manufacturerApplications: (state) => state.manufacturerApplications
    },

    mutations: {
        setFirmApplicationsPromise (state, promise) {
            state.firmApplicationsPromise = promise
        },

        setFirmApplications (state, applications) {
            state.firmApplicationsPromise = null
            state.firmApplications.splice(0,
                state.firmApplications.length, ...applications)
        },

        setManufacturerApplicationsPromise (state, promise) {
            state.manufacturerApplicationsPromise = promise
        },

        setManufacturerApplications (state, applications) {
            state.manufacturerApplicationsPromise = null
            state.manufacturerApplications.splice(0,
                state.manufacturerApplications.length, ...applications)
        }
    },

    actions: {
        loadFirmApplications ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.firmApplicationsPromise) {
                    return state.firmApplicationsPromise
                }
                if (state.firmApplications.length !== 0) {
                    return Promise.resolve(state.firmApplications)
                }
            }
            const promise = getFirms()
                .then(applications => {
                    commit("setFirmApplications", applications)
                })
                .catch(error => {
                    commit("setFirmApplicationsPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setFirmApplicationsPromise", promise)
            return promise
        },

        loadManufacturerApplications ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.manufacturerApplicationsPromise) {
                    return state.manufacturerApplicationsPromise
                }
                if (state.manufacturerApplications.length !== 0) {
                    return Promise.resolve(state.manufacturerApplications)
                }
            }
            const promise = getManufacturers()
                .then(applications => {
                    commit("setManufacturerApplications", applications)
                })
                .catch(error => {
                    commit("setManufacturerApplicationsPromise", null)
                    console.log(`Unable to load data: ${ error.message }`)
                    throw error
                })
            commit("setManufacturerApplicationsPromise", promise)
            return promise
        }
    }
}
