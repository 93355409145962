<template>
    <div class="content">
        <h1>Manufacturer “{{ manufacturer.company_name }}”</h1>

        <b-button-toolbar
            aria-label="Actions"
            key-nav
            class="mb-3"
        >
            <b-button-group class="mx-1">
                <b-button
                    :to="{ name: 'admin-edit-manufacturer', params: { id: $route.params.id } }"
                >
                    Edit Manufacturer Profile
                </b-button>

                <b-button
                    target="_blank"
                    :to="{ name: 'product-page', params: { id: $route.params.id } }"
                >
                    Preview Profile
                </b-button>
            </b-button-group>

            <b-button-group class="mx-1">
                <b-button
                    variant="danger"
                    @click="onClickDelete"
                >
                    Delete
                </b-button>
            </b-button-group>

            <b-button-group
                v-if="isApprovalPending || canSuspend || canPublish"
                class="mx-1"
            >
                <b-button
                    v-if="isApprovalPending"
                    variant="success"
                    @click="onClickApprove"
                >
                    Approve
                </b-button>
                <b-button
                    v-if="isApprovalPending"
                    variant="danger"
                    @click="onClickDeny"
                >
                    Deny
                </b-button>
                <b-button
                    v-if="canSuspend"
                    variant="warning"
                    @click="onClickSuspend"
                >
                    Suspend
                </b-button>
                <b-button
                    v-if="canPublish"
                    variant="success"
                    @click="onClickPublish"
                >
                    Publish
                </b-button>
            </b-button-group>
        </b-button-toolbar>

        <b-table-simple
            small
        >
            <b-tbody>
                <b-tr
                    v-for="field of fields"
                    :key="field.key"
                >
                    <b-td><strong>{{ field.label }}</strong></b-td>
                    <b-td>
                        <template v-if="manufacturer[field.key] === null">
                            <span class="special">&lt;NULL&gt;</span>
                        </template>

                        <template v-else-if="field.type === 'datetime'">
                            {{ manufacturer[field.key] | date }}
                        </template>

                        <template v-else-if="field.type === 'image'">
                            <b-img
                                :src="manufacturer[field.key]"
                                fluid
                                alt="Responsive image"
                            />
                        </template>

                        <template v-else-if="field.type === 'country'">
                            {{ manufacturer[field.key] | country }}
                        </template>

                        <template v-else-if="field.type === 'region'">
                            {{ manufacturer[field.key] | region(manufacturer.country) }}
                        </template>

                        <template v-else-if="field.type === 'string' && manufacturer[field.key] === ''">
                            <span class="special">&lt;BLANK&gt;</span>
                        </template>

                        <template v-else-if="field.type === 'market'">
                            {{ markets[manufacturer[field.key]] || manufacturer[field.key] }}
                        </template>

                        <template v-else-if="field.type === 'applications'">
                            {{ applications[manufacturer[field.key]] || applications[field.key] }}
                        </template>

                        <template v-else-if="field.type === 'quick_links'">
                            {{ (manufacturer[field.key] || []).map(ql =>
                                `${ quickLinks[ql.type] }: ${ ql.url }`).join(', ') }}
                        </template>

                        <template v-else-if="field.type === 'links'">
                            {{ (manufacturer[field.key] || []).map(l =>
                                `${ l.title }: ${ l.url }`).join(', ') }}
                        </template>

                        <template v-else-if="field.type === 'profile-state'">
                            {{ manufacturerProfileState[manufacturer[field.key]] || manufacturer[field.key] }}
                        </template>

                        <template v-else>
                            {{ manufacturer[field.key] }}
                        </template>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <h3>Images</h3>
        <b-table-simple
            class="images-table"
            small
        >
            <b-thead>
                <b-tr>
                    <b-th>Kind</b-th>
                    <b-th>Caption</b-th>
                    <b-th>Position</b-th>
                    <b-th>CSI Index Entry</b-th>
                    <b-th>Image</b-th>
                </b-tr>
            </b-thead>

            <b-tbody>
                <b-tr
                    v-for="image in manufacturer.images"
                    :key="image.id"
                >
                    <b-td style="vertical-align: middle;">
                        {{ image.kind }}
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        <span
                            v-if="!image.caption"
                        >
                            &mdash;
                        </span>

                        <span
                            v-else
                        >
                            {{ image.caption }}
                        </span>
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        {{ image.position }}
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        <span
                            v-if="!image.csi_index_entry"
                        >
                            &mdash;
                        </span>

                        <router-link
                            v-else
                            :to="{ name: 'admin-show-csi-index-entry', params: { id: image.csi_index_entry.id } }"
                        >
                            {{ image.csi_index_entry.code }} {{ image.csi_index_entry.title }}
                        </router-link>
                    </b-td>

                    <b-td style="vertical-align: middle;">
                        <b-img
                            :src="image.image_path"
                            fluid
                            thumbnail
                        />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <template
            v-if="!!manufacturer.profile_properties && manufacturer.profile_properties.length > 0"
        >
            <h3>Properties</h3>
            <b-table-simple
                class="images-table"
                small
            >
                <b-thead>
                    <b-tr>
                        <b-th>Name</b-th>
                        <b-th>Kind</b-th>
                        <b-th>Value</b-th>
                        <b-th>Caption</b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr
                        v-for="property in manufacturer.profile_properties"
                        :key="property.id"
                    >
                        <b-td style="vertical-align: middle;">
                            {{ property.name }}
                        </b-td>

                        <b-td style="vertical-align: middle;">
                            {{ property.kind }}
                        </b-td>

                        <b-td style="vertical-align: middle;">
                            <span
                                v-if="property.kind === 'address'"
                            >
                                {{ [property.value.country, property.value.state, property.value.city, property.value.zip, property.value.data, property.value.address2].join(', ') }}
                            </span>

                            <span
                                v-else
                            >
                                {{ property.value.data }}
                            </span>
                        </b-td>

                        <b-td style="vertical-align: middle;">
                            {{ property.caption }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>

        <template
            v-if="!!manufacturer.csi_sub_divisions && manufacturer.csi_sub_divisions.length > 0"
        >
            <h3>CSI MasterFormat Index Entries</h3>
            <b-table-simple
                class="images-table"
                small
            >
                <b-thead>
                    <b-tr>
                        <b-th>Type</b-th>
                        <b-th>Code</b-th>
                        <b-th>Title</b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr
                        v-for="sub_division in manufacturer.csi_sub_divisions"
                        :key="sub_division.id"
                    >
                        <b-td style="vertical-align: middle;">
                            {{ sub_division.csi_index_entry.entry_type }}
                        </b-td>

                        <b-td style="vertical-align: middle;">
                            <router-link
                                :to="{ name: 'admin-show-csi-index-entry', params: { id: sub_division.csi_index_entry.id } }"
                            >
                                {{ sub_division.csi_index_entry.code }}
                            </router-link>
                        </b-td>

                        <b-td style="vertical-align: middle;">
                            <router-link
                                :to="{ name: 'admin-show-csi-index-entry', params: { id: sub_division.csi_index_entry.id } }"
                            >
                                {{ sub_division.csi_index_entry.title }}
                            </router-link>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>

        <h3>Admin Edits</h3>
        <b-table-simple
            small
        >
            <b-thead>
                <b-tr>
                    <b-th>Admin</b-th>
                    <b-th>Column</b-th>
                    <b-th>Old Value</b-th>
                    <b-th>New Value</b-th>
                    <b-th>Time</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    v-for="edit in manufacturer.admin_edits"
                    :key="edit.id"
                >
                    <b-td>{{ edit.admin.username }}</b-td>
                    <b-td>{{ edit.column }}</b-td>
                    <b-td>{{ edit.old_value ? edit.old_value : "NULL" }}</b-td>
                    <b-td>{{ edit.new_value ? edit.new_value : "NULL" }}</b-td>
                    <b-td>{{ edit.created_at | date }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {
    getManufacturer,
    saveManufacturer,
    deleteManufacturer
} from "api/admin/manufacturers"
import quickLinks from "enums/quick-links"
import applications from "enums/applications"
import markets from "enums/markets"
import { manufacturerProfileState } from "enums/profile-states"
import { optionsToDictionary } from "mixins/collection"

export default {
    data () {
        return {
            quickLinks,
            fields: [
                { key: "id", label: "ID", type: "number" },
                { key: "status", label: "Status", type: "number" },
                { key: "div", label: "Div", type: "string" },
                { key: "uid", label: "UID", type: "string" },
                { key: "profile_state", label: "Profile State", type: "profile-state" },
                { key: "profile_type", label: "Profile Type", type: "profile-type" },
                { key: "company_name", label: "Company Name", type: "string" },
                { key: "email", label: "Email", type: "string" },
                { key: "phone", label: "Phone", type: "string" },
                { key: "country", label: "Country", type: "country" },
                { key: "state", label: "State", type: "region" },
                { key: "zip", label: "Zip", type: "string" },
                { key: "city", label: "City", type: "string" },
                { key: "address", label: "Address", type: "string" },
                { key: "site", label: "Site", type: "string" },
                // { key: "profile_type", label: "Profile Type", type: "string" },
                { key: "employees", label: "Employees", type: "string" },
                { key: "year_founded", label: "Year Founded", type: "string" },
                { key: "market", label: "Market", type: "market" },
                // { key: "green_products", label: "Green Products", type: "string" },
                { key: "statement", label: "Statement", type: "string" },
                { key: "principals", label: "Principals", type: "string" },
                { key: "applications", label: "Applications", type: "applications" },
                { key: "linkedin_link", label: "LinkedIn Link", type: "string" },
                { key: "twitter_link", label: "X Link", type: "string" },
                { key: "facebook_link", label: "Facebook Link", type: "string" },
                { key: "instagram_link", label: "Instagram Link", type: "string" },
                { key: "request_info_link", label: "Request Info Email", type: "string" },
                { key: "request_samples_link", label: "Request Samples Email", type: "string" },
                { key: "request_quote_link", label: "Request Quote Email", type: "string" },
                { key: "products_link", label: "Products Link", type: "string" },
                { key: "catalogs_link", label: "Catalogs Link", type: "string" },
                { key: "videos_link", label: "Videos Link", type: "string" },
                { key: "specifications_link", label: "Specifications Link", type: "string" },
                { key: "leed_info_link", label: "Leed Info Link", type: "string" },
                { key: "cad_link", label: "CAD Link", type: "string" },
                { key: "bim_link", label: "BIM Link", type: "string" },
                { key: "revit_link", label: "Revit Link", type: "string" },
                { key: "ceu_link", label: "CEU Link", type: "string" },
                // { key: "quick_links", label: "Quick Links", type: "quick_links" },
                { key: "green_statement", label: "Green Statement", type: "string" },
                { key: "green_statement_links", label: "Green Statement Links", type: "links" },
                { key: "products", label: "Products", type: "string" },
                { key: "product_links", label: "Product Links", type: "links" },
                { key: "latest_products", label: "Latest Products", type: "string" },
                { key: "latest_products_links", label: "Latest Products Links", type: "links" },
                { key: "showrooms_url", label: "Showrooms URL", type: "string" },
                { key: "showrooms_note", label: "Showrooms Note", type: "string" },
                { key: "keyword_list", label: "Keywords", type: "string" },
                { key: "approved_at", label: "Approved", type: "datetime" },
                { key: "created_at", label: "Created", type: "datetime" },
                { key: "updated_at", label: "Updated", type: "datetime" },
            ],
            manufacturer: {
                company_name: "",
                specializations: [],
                profile_state: "",
                profile_type: "",
            },
            markets: optionsToDictionary(markets),
            applications: optionsToDictionary(applications),
            manufacturerProfileState,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        // manufacturerSpecializations () {
        //     return this.manufacturer.specializations.map(specialization => {
        //         return specialization.parent && specialization.category_name
        //             ? `${specialization.parent.category_name}: ${specialization.name}`
        //             : specialization.name
        //     }).join(", ")
        // },

        isApprovalPending () {
            return ["first-submitted", "second-submitted"].includes(this.manufacturer.profile_state)
        },

        canSuspend () {
            return this.manufacturer.profile_state === "published"
        },

        canPublish () {
            return this.manufacturer.profile_state === "suspended"
        },
    },

    mounted () {
        this.loadManufacturerProfile()
    },

    methods: {
        loadManufacturerProfile () {
            console.log("--- loadManufacturerProfile", this.$route.params.id)
            getManufacturer(this.adminToken, { id: this.$route.params.id })
                .then(result => {
                    Object.assign(this.manufacturer, result)
                    console.log(this.manufacturer)
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to load manufacturer",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickDelete () {
            const message = `Are you sure you want to delete “${ this.manufacturer.company_name }”? This manufacturer will be deleted immediately. You can't undo this action.`

            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Conmanufacturer",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConmanufacturered => {
                    if (isConmanufacturered) {
                        deleteManufacturer(this.adminToken, this.manufacturer)
                            .then(() => {
                                // this.$bvToast.toast("Manufacturer deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-manufacturers" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        updateProfileState (profileState) {
            const record = {
                id: this.manufacturer.id,
                profile_state: profileState
            }
            saveManufacturer(this.adminToken, record)
                .then(result => {
                    Object.assign(this.manufacturer, result)
                    this.$bvToast.toast("Succeeded!", {
                        title: "Save status",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickApprove () {
            let newProfileState = null
            switch (this.manufacturer.profile_state) {
            case "first-submitted":
                newProfileState = "first-approved"
                break
            case "second-submitted":
                newProfileState = "published"
                break
            default:
                break
            }
            if (newProfileState) {
                this.updateProfileState(newProfileState)
            }
        },

        onClickDeny () {
            let newProfileState = null
            switch (this.manufacturer.profile_state) {
            case "first-submitted":
                newProfileState = "first-saved"
                break
            case "second-submitted":
                newProfileState = "second-saved"
                break
            default:
                break
            }
            if (newProfileState) {
                this.updateProfileState(newProfileState)
            }
        },

        onClickSuspend () {
            this.updateProfileState("suspended")
        },

        onClickPublish () {
            this.updateProfileState("published")
        },
    }
}
</script>

<style lang="scss" scoped>
.special {
    background-color: #ccc;
    font-weight: bold;
    font-size: 14px;
}
</style>
