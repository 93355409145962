import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getVideos = (token, { page = 1, sortBy = "", sortDesc = false, query = null } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/videos", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getVideo = (token, { id }) => {
    return getRequest(`/admin/videos/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveVideo = (token, video) => {
    if (video.id) {
        let { id, ...videoWithoutId } = video
        return patchRequest(`/admin/videos/${ id }`, {
            video: videoWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/videos", {
            video: video
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteVideo = (token, id) => {
    return deleteRequest(`/admin/videos/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
