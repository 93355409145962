<template>
    <b-modal
        :id="id"
        modal-class="newsletter-subscription-modal"
        hide-header
        hide-footer
        centered
    >
        <a
            class="close-button"
            href="#"
            @click.stop.prevent="onClose"
        >
            <b-img
                :src="require('assets/images/modal-close.png')"
                alt="Fluid-grow image"
                width="16"
                height="16"
            />
        </a>

        <div class="d-flex justify-content-center align-items-center">
            <b-img
            :src="require('assets/images/logo-small.png')"
            alt="Fluid-grow image"
            width="68"
            height="65"
            />
            <h1 style="text-align:left;margin-left: 10px;margin-bottom: 0;">
                <span>
                    {{ title }}<br>
                    {{ sub_title }}
                </span>
            </h1>
        </div>

        <hr>

        <div class="content">
            <p class="subscriber">
                {{ subscriberMessage }}
            </p>

            <p
                v-show="!!errorMessage"
                class="error"
            >
                {{ errorMessage }}
            </p>

            <b-button
                v-if="!!subscriberMessage"
                type="button"
                variant="primary"
                squared
                block
                @click.stop.prevent="onContinue"
            >
                Continue
            </b-button>

            <b-form v-else @submit.stop.prevent="onSubmit">
                <b-form-input
                    v-model="name"
                    type="text"
                    class="mb-2"
                    placeholder="Your Name"
                    required
                    autocomplete="off"
                    autocapitalize="none"
                    autocorrect="off"
                />
                <b-form-input
                    v-model="email"
                    type="email"
                    class="mb-2"
                    placeholder="Email Address"
                    required
                    autocomplete="off"
                    autocapitalize="none"
                    autocorrect="off"
                />
                <b-form-select v-model="occupation"
                               :options="descriptionOptions"
                               class="mb-2"
                               name="occupation"
                               required>
                    <template #first>
                        <b-form-select-option :value="null">
                            Describe Yourself
                        </b-form-select-option>
                    </template>
                </b-form-select>

                <b-form-select
                    class="mb-2"
                    name="country"
                    v-model="country"
                    :options="countries"
                >
                    <template #first>
                        <b-form-select-option :value="null">
                            Enter Country
                        </b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-select
                    class="mb-2"
                    name="state"
                    v-model="state"
                    :options="states"
                >
                    <template #first>
                        <b-form-select-option :value="null">
                            Enter State
                        </b-form-select-option>
                    </template>
                </b-form-select>

                <b-button
                    type="submit"
                    variant="primary"
                    squared
                    block
                >
                    Submit
                </b-button>
            </b-form>
        </div>
        <!-- <h5></h5> -->
        <!--  -->
        <!-- <a href="" @click.prevent.end="onLoginClicked">Please log in here</a> -->
    </b-modal>
</template>

<script>
import { mapActions } from "vuex"
import { descriptionOptions } from "enums/descriptions"
import { createNewsletterSubscription } from "api/newsletter-subscription"
import countryRegionData from "country-region-data/data"

export default {
    props: {
        type: {
            type: String,
            default: "newsletter",
            validator: value => [
                "newsletter",
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "newsletter-subscription-modal",
        }
    },

    data () {
        return {
            isForgotPassword: false,
            name: null,
            email: null,
            occupation: null,
            country: null,
            state: null,
            errorMessage: null,
            titles: {
                newsletter: "Newsletter Signup",
            },
            sub_titles: {
                firm_admin: "Administrator Log In",
                manufacturer_admin: "Administrator Log In"
            },
            button_text_infos: {
                firm_admin: "Design Firm Application",
                manufacturer_admin: "Manufacturer Application",
                visiting: "Membership info",
                favorites: "Membership info",
                saveFavorites: "Membership info",
                requestInfo: "Membership info",
                requestBid: "Membership info",
                requestSample: "Membership info",
                admin: "Membership info"
            },
            otherSubscriber: null,
            descriptionOptions,
        }
    },

    computed: {
        title () { return this.titles[this.type] },
        sub_title () { return this.sub_titles[this.type] },
        button_text_info () { return this.button_text_infos[this.type]},
        subscriberMessage () { 
            return this.otherSubscriber
        },
        countries () {
            return countryRegionData
                // .filter(c => c.countryShortCode === "US" || c.countryShortCode === "CA")
                .map(country => {
                    return {
                        value: country.countryShortCode,
                        text: country.countryName
                    }
                })
        },
        states () {
            let prohibited_states = ["American Samoa","Micronesia","Guam","Marshall Islands","Palau","Puerto Rico","Virgin Islands","Armed Forces Americas","Armed Forces Europe","Armed Forces Pacific","Armed Forces Europe, Canada, Africa and Middle East"]
            const country = this.country
            if (country) {
                const entry = countryRegionData.find(
                    entry => entry.countryShortCode === country)
                if (entry) {
                    return entry.regions.filter(f => !prohibited_states.includes(f.name) ).map(region => {
                        return { value: region.shortCode, text: region.name }
                    })
                }
            }
            return []
        },
    },

    methods: {

        onClose () {
            this.$bvModal.hide(this.id);
            window.location.reload();
        },

        onContinue () {
            this.$bvModal.hide(this.id);
            Object.assign(this.$data, this.$options.data());
            this.$bvToast.toast("Succeeded!", {
                title: "Successfully created!",
                variant: "success",
                autoHideDelay: 5000
            })
        },

        onSubmit() {
            if (!this.email) {
                this.errorMessage = "Please enter your email."
                return
            }

            createNewsletterSubscription({ name: this.name, email: this.email, occupation: this.occupation, country: this.country, state: this.state })
                .then((data) => {
                    this.otherSubscriber = data.message;
                    // this.$bvModal.hide(this.id)
                    // this.$forceUpdate();
                    // this.$router.go();
                })
                .catch((error) => {
                    if (error && error.message) {
                        this.errorMessage = error.message
                    } else {
                        this.errorMessage = "An error occurred. Please try again later."
                    }
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })

                    setTimeout(() => {
                        this.errorMessage = null
                    }, 3000)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
    .close-button {
        position: absolute;
        top: 18px;
        right: 22px;

        &:hover {
            opacity: 0.7;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.65;
        color: #000;
        text-align: center;
        margin-bottom: 35px
    }

    hr {
        margin-bottom: 42px;
        border-top: 2px solid #979797;
    }

    .content {
        margin: 0 80px;

        .btn {
            text-transform: uppercase;
        }

        .b-button {
            text-transform: uppercase;
        }

        .btn-primary {
            text-transform: uppercase;
        }


    }
    .btn {
            text-transform: uppercase;
        }

        .b-button {
            text-transform: uppercase;
        }

        .btn-primary {
            text-transform: uppercase;
        }

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
        text-align: center;
        margin: 0;

        

        &.subscriber {
            font-style: italic;
            margin-bottom: 22px;
        }

        &.error {
            color: #ff8888;
            margin-bottom: 10px;
        }

        &.forgot-password {
            font-style: italic;
            margin-top: 4px;
            margin-bottom: 18px;
        }

        &.non-subscriber {
            margin-top: 35px;
            margin-bottom: 22px;
        }

        a {
            color: #225a90;
            text-decoration: underline;
        }
    }
</style>
