const deepCopy = (object) => {
    if (typeof object !== "object") {
        return object
    }
    if (!object) {
        return object
    }
    if (object instanceof Date) {
        return new Date(object.getTime())
    }
    var result = object.constructor()
    for (var key in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(key)) {
            result[key] = deepCopy(object[key])
        }
        result[key] = deepCopy(object[key])
    }
    return result
}

export {
    deepCopy,
}
