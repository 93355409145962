import { products } from "api/products"

export default {
    namespaced: true,

    state: {
        loadingProductsPromise: null,
        products: [],
    },

    getters: {
        products: (state) => state.products,
    },

    mutations: {
        setLoadingProductsPromise (state, promise) {
            state.loadingProductsPromise = promise
        },

        setProducts (state, products) {
            state.loadingProductsPromise = null
            products.forEach(product => {
                const prices = product.prices
                const defaultPrice = prices.find(p => p.default) || prices[0]
                product.default_price = defaultPrice
            })
            state.products.splice(0, state.products.length, ...products)
        }
    },

    actions: {
        loadProducts ({ commit, state }, { force = false } = {}) {
            if (!force) {
                if (state.loadingProductsPromise) {
                    return state.loadingProductsPromise
                }
                if (state.products.length !== 0) {
                    return Promise.resolve(state.products)
                }
            }
            const promise = products()
                .then(products => {
                    commit("setProducts", products)
                })
                .catch(error => {
                    commit("setLoadingProductsPromise", null)
                    console.log(`Unable to load entry: ${ error.message }`)
                    throw error
                })
            commit("setLoadingProductsPromise", promise)
            return promise
        },
    }
}
