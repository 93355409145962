import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFeaturedManufacturers = (token, { page = 1, per_page = 25, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page, per_page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'created_at'
        params["direction"] = "desc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/featured_manufacturers", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFeaturedManufacturer = (token, { id }) => {
    return getRequest(`/admin/featured_manufacturers/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFeaturedManufacturer = (token, featuredManufacturer) => {
    if (featuredManufacturer.id) {
        let { id, ...featuredManufacturerWithoutId } = featuredManufacturer
        return patchRequest(`/admin/featured_manufacturers/${ id }`, {
            featured_manufacturer: featuredManufacturerWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/featured_manufacturers", {
            featured_manufacturer: featuredManufacturer
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFeaturedManufacturer = (token, featuredManufacturer) => {
    return deleteRequest(`/admin/featured_manufacturers/${ featuredManufacturer.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const bulkActionFeaturedManufacturer = (token, b_action, requestIds) => {
    if (requestIds) {
        return patchRequest("/admin/featured_manufacturers/bulk_action", {
            item_ids: requestIds,
            b_action: b_action
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}
