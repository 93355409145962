import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getEventStories = (token, { page = 1, sortBy = "", sortDesc = false } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    return getRequest("/admin/event_stories", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getEventStory = (token, { id }) => {
    return getRequest(`/admin/event_stories/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveEventStory = (token, eventStory) => {
    if (eventStory.id) {
        let { id, ...eventStoryWithoutId } = eventStory
        return patchRequest(`/admin/event_stories/${ id }`, {
            event_story: eventStoryWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/event_stories", {
          event_story: eventStory
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteEventStory = (token, eventStory) => {
    return deleteRequest(`/admin/event_stories/${ eventStory.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
