import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getBanners = (token, { page = 1, sortBy = "event_date", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/banners", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getBanner = (token, { id }) => {
    return getRequest(`/admin/banners/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveBanner = (token, banner) => {
    if (banner.id) {
        let { id, ...bannerWithoutId } = banner
        return patchRequest(`/admin/banners/${ id }`, {
            banner: bannerWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/banners", {
            banner: banner
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteBanner = (token, banner) => {
    return deleteRequest(`/admin/banners/${ banner.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
