<template>
    <div class="content">
        <h1>CSI Sub Divisions</h1>

        <b-row>
            <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-csi-sub-division' }"
                        >
                            New CSI Sub Divisions
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>

            <b-col class="d-flex justify-content-center align-items-center">
              <div class="number-slider">
                <div class="arrow" @click="scrollLeft">&#9664;</div>
                <div class="number-wrapper" style="width: 400px; overflow: hidden;">
                  <div
                    class="number-container"
                    :style="{ transform: `translateX(-${currentPosition * numberWidth}px)` }"
                  >
                    <button
                      v-for="num in totalNumbers"
                      :key="num"
                      @click="scrollToProduct(num)"
                      style="width: 50px;"
                    >
                      {{ num < 10 ? '0' + num : num }}
                    </button>
                  </div>
                </div>
                <div class="arrow" @click="scrollRight">&#9654;</div>
              </div>
            </b-col>



            <b-col class="d-flex justify-content-end">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="lastPage"
                    :limit="7"
                    :to="{ name: 'admin-csi-sub-divisions' }"
                    :link-gen="linkGen"
                    :disabled="paginationDisable"
                    @page-click="onPageClick"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            @row-clicked="onClickRow"
        >
            <template #cell(entry_type)="row">
                {{ entryTypes[row.item.entry_type] }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(excluded)="row">
                {{ row.item.excluded ? 'Yes' : 'No' }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table>

        <div class="d-flex justify-content-end">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="lastPage"
                :limit="7"
                :to="{ name: 'admin-csi-sub-divisions' }"
                :link-gen="linkGen"
                :disabled="paginationDisable"
                @page-click="onPageClick"
            />
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getCsiIndexEntries } from "api/admin/csi-index-entries"
import { mapActions, mapGetters } from "vuex"
import { entryTypeOptions } from "enums/entry-types"
import { optionsToDictionary } from "mixins/collection"
import AdminPaginationMixin from "../../../mixins/admin_pagination.js"

export default {
    mixins: [AdminPaginationMixin],
    data () {
        return {
            paginationDisable: false,
            items: [],
            totalItems: 0,
            itemsPerPage: 1000,
            sortBy: "",
            sortDesc: false,
            entryTypes: optionsToDictionary(entryTypeOptions),
            fields: [
                { key: "id", label: "ID", sortable: true, class: "column-left" },
                { key: "entry_type", label: "Type", sortable: true, class: "column-center" },
                { key: "code", label: "Code", sortable: true, class: "column-center" },
                { key: "title", label: "Title", sortable: true, class: "column-left" },
                { key: "excluded", label: "Excluded", sortable: false, class: "column-center" },
                { key: "created_at", label: "Created", sortable: true, class: "column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "column-center" },
            ],
            currentPage: 1,
            lastPage: 5,
            currentPosition: 0,
            totalNumbers: 50,
            visibleNumbers: 10,
            numberWidth: 50,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadCsiIndexEntries",

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },
    },

    mounted () {
        this.loadCsiIndexEntries()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),
        onSearch () {
            this.loadCsiIndexEntries();
        },
        linkGen () {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query)
            }
        },

        loadCsiIndexEntries () {
            // this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            this.paginationDisable = true;
            getCsiIndexEntries(this.adminToken, {
                page: this.currentPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                type: "subdivision",
                query: this.$route.query.query
            })
                .then(result => {
                    this.currentPage = result.meta.page
                    this.totalItems = result.meta.total_pages * result.meta.per_page
                    this.itemsPerPage = result.meta.per_page
                    this.lastPage = result.meta.total_pages
                    this.items.splice(0, this.items.length, ...result.data)
                    this.paginationDisable = false;
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-edit-csi-sub-division",
                params: { id: item.id }
            })
        },

        scrollLeft() {
          if (this.currentPosition > 0) {
            this.currentPosition -=7;
          }
        },
        scrollRight() {
          if (this.currentPosition < this.totalNumbers - this.visibleNumbers) {
            this.currentPosition +=7;
          }
        },

        scrollToProduct(num) {
            const searchCode = num < 10 ? '0' + num : num.toString();

            const rows = document.querySelectorAll('#index-table tbody tr');
            let targetRow = null;

            for (let row of rows) {
                const codeCell = row.querySelector('td[data-label="Code"] div');
                if (codeCell && codeCell.textContent.trim().startsWith(searchCode)) {
                  row.scrollIntoView({ behavior: 'smooth' });
                  break;
                }
            }

            if (targetRow) {
                targetRow.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.log(`No product found with code starting with ${searchCode}`);
            }
        },
    }
}
</script>

<style scoped>
.number-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 380px;
  overflow: hidden;
  color: #fff;
  background-color: #225a90;
  border-color: #225a90;
}

.arrow {
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  color: #fff;
  background-color: #225a90;
  border-color: #225a90;
}

.number-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  color: #fff;
  background-color: #225a90;
  border-color: #225a90;
}

.number-container div {
  min-width: 40px;
  text-align: center;
  font-size: 18px;
  padding: 5px;
  color: #fff;
  background-color: #225a90;
  border-color: #225a90;
}

.number-wrapper {
  overflow: hidden;
  flex: 1;
  color: #fff;
  background-color: #225a90;
  border-color: #225a90;
}
</style>
