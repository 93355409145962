<template>
    <main>
        <router-view />
    </main>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
main {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    text-align: center;
}
</style>
