import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getBuildingProducts = (token, { page = 1, sortBy = "", sortDesc = false, query } = {}) => {
    let params = { page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/building_products", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getBuildingProduct = (token, { id }) => {
    return getRequest(`/admin/building_products/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveBuildingProduct = (token, buildingProduct) => {
    if (buildingProduct.id) {
        let { id, ...buildingProductWithoutId } = buildingProduct
        return patchRequest(`/admin/building_products/${ id }`, {
            building_product: buildingProductWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/building_products", {
            building_product: buildingProduct
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteBuildingProduct = (token, buildingProduct) => {
    return deleteRequest(`/admin/building_products/${ buildingProduct.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
