<template>
    <div class="content">
        <h1>Featured Videos</h1>

        <b-row>
            <b-col>
                <b-button-toolbar
                    aria-label="Actions"
                    key-nav
                    class="mb-3"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            :to="{ name: 'admin-new-featured-video' }"
                        >
                            New Featured Video
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-pagination
                    v-model="page"
                    :total-rows="totalItems"
                    :per-page="itemsPerPage"
                    aria-controls="index-table"
                />
            </b-col>
        </b-row>

        <b-table
            id="index-table"
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-debounce="1000"
            table-class=""
            primary-key="id"
            stacked="md"
            show-empty
            small
            striped
            hover
            responsive
            @row-clicked="onClickRow"
        >
            <template #cell(count)="row">
                {{ (row.index + 1) + ($route.query.page ? ($route.query.page - 1) * itemsPerPage : 0 ) }}
            </template>
            
            <template #cell(active)="row">
                {{ row.item.active ? 'Yes' : 'No' }}
            </template>

            <template #cell(title)="row">
                {{ row.item.video.title }}
            </template>

            <template #cell(created_at)="row">
                {{ row.item.created_at | date }}
            </template>

            <template #cell(updated_at)="row">
                {{ row.item.updated_at | date }}
            </template>
        </b-table>

        <div class="d-flex justify-content-end">
            <b-pagination
                v-model="page"
                :total-rows="totalItems"
                :per-page="itemsPerPage"
                aria-controls="index-table"
            />
        </div>
    </div>
</template>

<script>
import { ApiError } from "api/base"
import { getFeaturedVideos } from "api/admin/featured-videos"
import { mapActions, mapGetters } from "vuex"

export default {
    data () {
        return {
            items: [],
            page: 1,
            totalItems: 0,
            itemsPerPage: 25,
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "count", label: "#", sortable: true, class: "column-left" },
                { key: "id", label: "ID", sortable: true, class: "text-nowrap column-left" },
                { key: "title", label: "Title", sortable: true, class: "text-nowrap column-left" },
                { key: "active", label: "Active", sortable: true, class: "text-nowrap column-left" },
                { key: "created_at", label: "Created", sortable: true, class: "text-nowrap column-center" },
                { key: "updated_at", label: "Updated", sortable: true, class: "text-nowrap column-center" }
            ],
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    watch: {
        "$route": "loadVideos",

        page (newValue) {
            if (newValue !== (this.$route.query.page || 1)) {
                const query = Object.assign({}, this.$route.query, {
                    page: newValue === 1 ? undefined : newValue
                })

                this.$router.replace({ name: this.$route.name, query })
            }
        },

        sortBy (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortBy: newValue,
                sortDesc: this.sortDesc
            })
            this.$router.push({ name: this.$route.name, query })
        },

        sortDesc (newValue) {
            const query = Object.assign({}, this.$route.query, {
                sortDesc: newValue,
                sortBy: this.sortBy
            })
            this.$router.push({ name: this.$route.name, query })
        },
    },

    mounted () {
        this.loadVideos()
    },

    methods: {
        ...mapActions("account", ["adminSignout"]),

        loadVideos () {
            this.page = this.$route.query.page || 1
            this.sortBy = this.$route.query.sortBy || ""
            this.sortDesc = !!this.$route.query.sortDesc
            getFeaturedVideos(this.adminToken, {
                page: this.page,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                query: this.$route.query.query
            })
                .then(result => {
                    this.page = result.meta.page
                    this.totalItems = result.meta.total_pages * result.meta.per_page
                    this.itemsPerPage = result.meta.per_page
                    this.items.splice(0, this.items.length, ...result.data)
                })
                .catch(error => {
                    if (error instanceof ApiError && error.status === 401) {
                        // Not authorized
                        this.adminSignout(null)
                        this.$router.go()
                    } else {
                        console.log("Error", error)
                    }
                })
        },

        onClickRow (item) {
            this.$router.push({
                name: "admin-show-featured-video",
                params: { id: item.id }
            })
        },
    }
}
</script>

<style lang="scss">
#index-table {
    th {
        white-space: nowrap !important;

        div {
            white-space: nowrap !important;            
        }
    }
}
</style>
