<template>
    <div class="content">
        <edit-columns
            :definitions="attributeDefinitions"
            :record="record"
            @save="onSave"
            title="User"
            :enableTitle="true"
        />
        <div class="pt-4">
            <h3>Managed Firms</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>ID</b-th>
                        <b-th>Created</b-th>
                        <b-th>Name</b-th>
                        <b-th>URL</b-th>
                        <b-th>City</b-th>
                        <b-th>ST/PR</b-th>
<!--                         <b-th>ID</b-th>
                        <b-th>Company Name</b-th>
                        <b-th>Tier</b-th>
                        <b-th>Profile State</b-th>
                        <b-th>Country</b-th>
                        <b-th>State</b-th>
                        <b-th>City</b-th>
                        <b-th>Address</b-th> -->
                    </b-tr>
                </b-thead>
                <b-tbody>
<!--                     <b-tr
                        v-for="profile in record.firm_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-firm', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.tier }}</b-td>
                        <b-td class="text-nowrap">{{ profile.profile_state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.country }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.address }}</b-td>
                    </b-tr> -->
                    <b-tr
                        v-for="profile in record.firm_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">{{ profile.created_at | date }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-firm', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.site }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>

        <div>
            <h3>Managed Manufacturers</h3>
            <b-table-simple
                small
                responsive
            >
                <b-thead>
                    <b-tr>
                        <b-th>ID</b-th>
                        <b-th>Company Name</b-th>
                        <b-th>Status</b-th>
                        <b-th>Profile State</b-th>
                        <b-th>Country</b-th>
                        <b-th>State</b-th>
                        <b-th>City</b-th>
                        <b-th>Address</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="profile in record.manufacturer_profiles"
                        :key="profile.id"
                    >
                        <b-td class="text-nowrap">{{ profile.id }}</b-td>
                        <b-td class="text-nowrap">
                            <b-link
                                :to="{ name: 'admin-show-manufacturer', params: { id: profile.id } }"
                            >
                                {{ profile.company_name }}
                            </b-link>
                        </b-td>
                        <b-td class="text-nowrap">{{ profile.status }}</b-td>
                        <b-td class="text-nowrap">{{ profile.profile_state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.country }}</b-td>
                        <b-td class="text-nowrap">{{ profile.state }}</b-td>
                        <b-td class="text-nowrap">{{ profile.city }}</b-td>
                        <b-td class="text-nowrap">{{ profile.address }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { getUser, saveUser } from "api/admin/users"
import { mapGetters } from "vuex"
import EditColumns from "../shared/edit-columns"

export default {
    components: { EditColumns },

    data () {
        const attributeDefinitions = [
            { name: "email", type: "email", default: null },
            { name: "password", type: "password", default: "" },
            { name: "password_confirmation", type: "password", default: "" },
            { name: "confirmation_token", type: "text", default: null },
            { name: "confirmed_at", type: "datetime", default: null },
            { name: "name", type: "text", default: "" },
            { name: "company_name", type: "text", default: "" },
            { name: "phone", type: "tel", default: null },
            { name: "country", type: "country", default: null },
            { name: "state", type: "state", default: null },
            { name: "city", type: "text", default: null },
            { name: "zip", type: "text", default: null },
            { name: "address", type: "text", default: null },
            { name: "description", type: "text", default: null },
            // { name: "manufacturer_profile_id", type: "manufacturer_profile", default: undefined },
            // { name: "firm_profile_id", type: "firm_profile", default: undefined },
        ]

        return {
            attributeDefinitions,
            record: {
                    id: null,
                    email: null,
                    confirmation_token: null,
                    confirmed_at: null,
                    name: null,
                    company_name: null,
                    phone: null,
                    country: null,
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                    description: null,
                    manufacturer_profile_id: null,
                    firm_profile_id: null,
                    created_at: null,
                    updated_at: null,
                    plan: null,
                    user_type: null,
                    manufacturer_profiles_count: 0,
                    firm_profiles_count: 0,
                    favorite_files: [],
                    favorite_firms: [],
                    favorite_manufacturers: [],
                    firm_profiles: [],
                    manufacturer_profiles: [],
                    admin_edits: []
                }
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getUser(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unable to load",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave (record) {
            saveUser(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-user",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },
    }
}
</script>
