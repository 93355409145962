export default {
    namespaced: true,

    state: {
        bodyClass: new Set(),
        isMobile: false,
        regions: localStorage.getItem("_regions") || []
    },

    getters: {
        bodyClass: state => state.bodyClass,
        isMobile: state => state.isMobile,
        regions: state => state.regions,
    },

    mutations: {
        updateBodyClass (state, { add = [], remove = [] }) {
            remove.forEach(_class => state.bodyClass.delete(_class))
            add.forEach(_class => state.bodyClass.add(_class))
            document.body.classList = [...state.bodyClass]
        },

        updateMobile (state, flag) {
            console.log("resize", flag)
            state.isMobile = !!flag
        },
    },

    actions: {
        changeBodyClass ({ commit }, { add = [], remove = [] }) {
            commit("updateBodyClass", { add, remove })
        },

        setMobile ({ commit }, flag) {
            commit("updateMobile", flag)
        },

        setRegions (regions) {
            this.regions = localStorage.setItem("_regions", regions)
        },

    }
}
