import Vue from "vue"
import Vuex from "vuex"

import account from "./modules/account"
import applications from "./modules/applications"
import manufacturers from "./modules/manufacturers"
import firms from "./modules/firms"
import products from "./modules/products"
import utils from "./modules/utils"

Vue.use(Vuex)
 
const store = new Vuex.Store({
    modules: {
        account,
        applications,
        firms,
        manufacturers,
        products,
        utils,
    }
})

export default store
