<template>
    <b-modal
        :id="id"
        modal-class="subscribe-modal"
        hide-header
        hide-footer
        centered
        size="lg"
    >
        <b-container fluid>
           <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.stop.prevent="onClose"
            >
                <span aria-hidden="true">&times;</span>
            </button>


            <a
                @click="onSubscription"
                style="cursor: pointer;"
            >
                <b-img
                    :src="require('assets/images/become-a-member.jpg')"
                    alt="Fluid-grow image"
                    fluid-grow
                />
            </a>
<!--             <b-row align-v="stretch">
                <b-col  sm="12" md="4" class="text-center pl-3">
                    <h5 class="text-left p-0 mb-0">Robust Results</h5>
                    <b-img
                        :src="require('assets/images/result_set-4.png')"
                        alt="Fluid-grow image"
                        fluid-grow
                        style="height: 85.57%;"
                        class="mt-3"
                    />
                </b-col>
                <b-col  sm="12" md="4" class="text-center">
                    <h5 class="text-left p-0 mb-0">Comprehensive Profiles</h5>
                    <b-img
                        :src="require('assets/images/result_set-3.png')"
                        alt="Fluid-grow image"
                        fluid-grow
                        class="mt-3"
                        style="height: 85.6%;"
                    />
                </b-col>

                <b-col class="px-4" sm="12" md="4" style="padding-bottom: 20px;">
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click.stop.prevent="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h5>{{ title }}</h5>

                    <ul class="mb-0">
                        <li class="pb-3">Access the extended Building Product Library of over 12,000 Manufacturers, the largest comprehensive collection.</li>
                        <li class="pb-3">Ability to request information, catalogs, samples, quotes directly.</li>
                        <li class="pb-3">Set up your private customizable “Favorites File” for both Building Products and Design Firms.</li>
                        <li class="pb-3">Professionals: Download the latest technical info, including: Specs, CAD, BIM, Revit, LEED. CEU, Videos.</li>
                        <li class="pb-3">Access a curated building + design video archive.</li>
                        <li class="pb-3">Receive monthly newsletters and the latest industry resources.</li>
                        <li>Opt-out at any time.</li> -->
<!--                         <li>Access an extended building product Library of 16,000 Manufacturers, representing the largest digital library online today.</li>
                        <li>The Latest technical information including Specs, CAD, BIM, Revit, LEED.</li>
                        <li>Ability to request info, catalogs, samples and quotes</li>
                        <li>Access to your private customizable "Favorites File"</li>
                        <li>Receive monthly newsletters and important industry resources (opt out at any time)</li> -->
<!--                         <li><span class="accent">Access</span> the largest digital Building Product Library. (Over 12,000 North American Manufacturers)</li>
                        <li><span class="accent">View</span> millions of quality &amp; proven building products &amp; furnishings.</li>
                        <li><span class="accent">Download</span> the latest technical information available from the manufactures including Specs, CAD, BIM, Revit, LEED CEU &amp; more.</li>
                        <li><span class="accent">Request</span> info, catalogs, samples and bids with our easy access Requests feature.</li>
                        <li><span class="accent">Save</span> your favorite manufacturers or firms to your customizable favorite files.</li> -->
                    <!-- </ul> -->

<!--                     <div class="subscription-price">
                        Membership start at $19.50 / month.
                    </div> -->

<!--                     <b-button
                        variant="primary"
                        class="px-5 text-capitalize w-100"
                        squared
                        @click="onSubscription"
                    >
                        {{see_more_button_name}}
                    </b-button> -->

<!--                     <div class="already-subscriber">
                        Already a member?
                        <a
                            href="#"
                            @click.prevent.end="onLoginClicked"
                        >
                            Please log in here
                        </a>
                    </div> -->
<!--                 </b-col>
            </b-row> -->
        </b-container>
    </b-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: value => [
                "becomeSubscriber",
                "saveFavorites",
                "requestInfo",
                "requestBid",
                "requestSample",
                "saveProfile"
            ].indexOf(value) !== -1
        },
        id: {
            type: String,
            default: "become_subscriber_modal"
        }
    },

    data () {
        return {
            titles: {
                becomeSubscriber: "Member Benefits",
                saveFavorites: "Subscribe to Save Favorites",
                requestInfo: "Subscribe to Request Info",
                requestBid: "Subscribe to Request Bid",
                requestSample: "Subscribe to Request Sample",
                saveProfile: "You have to be a member to save profiles"
            },
            see_more_button_names: {
                becomeSubscriber: 'See Membership options',
                // becomeSubscriber: "Membership Information",
                saveFavorites: "See More Information",
                requestInfo: "See More Information",
                requestBid: "See More Information",
                requestSample: "See More Information",
                saveProfile: "Membership Information"
            }
        }
    },

    computed: {
        title () { return this.titles[this.type] },
        see_more_button_name () { return this.see_more_button_names[this.type] },
    },

    methods: {
        onLoginClicked () {
            this.$bvModal.hide(this.id)
            this.$bvModal.show("signin_modal")
        },

        onSubscription () {
            this.$bvModal.hide(this.id)
            this.$router.push({ name: "membership-options" })
        },

        onClose () {
            this.$bvModal.hide(this.id)
        },
    },
}
</script>

<style lang="scss">
    .subscribe-modal {
        .modal-dialog {
            max-width: 1024px;
        }

        .modal-body {
            padding: 0;
        }

        .container-fluid {
            padding: 0;
        }
    }
</style>

<style lang="scss" scoped>
    .close {
        margin-top: 10px;
    }

    h5 {
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 0.14px;
        color: #000;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 14px;
        li + li {
            margin-top: 8px;
        }
    }

    li {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
   }

    .accent {
        text-decoration: underline;
        font-weight: 700;
    }

    .b-img{
        margin-top: 20px;
    }
    img{
        margin-top: 20px;
    }


    .subscription-price {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
    }

    .already-subscriber {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.29;
        color: #000;
        margin-top: 12px;
        margin-bottom: 30px;

        a {
            text-decoration: underline;
            color: #225a90;
        }
    }

    .close {
        transform: translate(-10px, -12px);
    }
</style>
