import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getCourses = (token, { page = 1, per_page = 100, sortBy = "", sortDesc = false } = {}) => {
    let params = { page, per_page }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'updated_at'
        params["direction"] = 'desc'
    }
    return getRequest("/admin/courses", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getCourse = (token, { id }) => {
    return getRequest(`/admin/courses/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveCourse = (token, course) => {
    if (course.id) {
        let { id, ...heroWithoutId } = course
        return patchRequest(`/admin/courses/${ id }`, {
            course: heroWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/courses", {
            course: course
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteCourse = (token, course) => {
    return deleteRequest(`/admin/courses/${ course.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}
