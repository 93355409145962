import { ApiError, getRequest } from "./base"

const blobImage = (blob_signed_id) => {
    return getRequest("/blob_image", { blob_signed_id })
}

export {
    ApiError,
    blobImage,
}
