<template>
    <div class="content">
        <h1>
            <template v-if="!$route.params.id">
                New Event Story
            </template>

            <template v-else>
                Edit Event Story #{{ $route.params.id }}
            </template>
        </h1>

        <edit-columns
            :definitions="attributeDefinitions"
            :record="record"
            @save="onSave"
        />
    </div>
</template>

<script>
import { getEventStory, saveEventStory } from "api/admin/event-stories"
import { mapGetters } from "vuex"
import EditColumns from "../shared/edit-columns"

export default {
    components: { EditColumns },

    data () {
        const attributeDefinitions = [
            { name: "title", type: "text", default: "" },
            { name: "body", type: "text", default: "" },
            { name: "image", type: "image", default: undefined },
        ]

        return {
            attributeDefinitions,
            record: null,
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),
    },

    mounted () {
        this.onLoad()
    },

    methods: {
        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getEventStory(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        this.record = record
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unable to load",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        onSave (record) {
            saveEventStory(this.adminToken, record)
                .then(newRecord => {
                    const isNewRecord = !record.id
                    this.record = newRecord
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-event-story",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                        this.$router.go(-1)
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },
    }
}
</script>
